<div
  #theme
  class="app-component mat-typography hide-scrollbars"
  [ngClass]="[appClasses()]"
  resizeObserver
  (resize)="onResize($event)"
  [attr.data-auth]="isAuthenticated() ? 'true' : 'false'"
>
  @if (showPleaseSubscribe() && !closedPleaseSubscribe()) {
    <ft-please-subscribe (close)="closedPleaseSubscribe.set(true)"></ft-please-subscribe>
  } @else {
    <ft-app-base-layout [baseBackground]="backgroundImage()"> </ft-app-base-layout>
  }
</div>
