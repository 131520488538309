<div
  #navbar
  class="navbar"
  [ngClass]="navbarClasses()"
  [ngStyle]="navbarStyles()"
  appEscapeKeyListener
  (escapeKeyDown)="closeMenu()"
>
  <!-- Authenticated Navbar -->
  @if (isAuthenticated()) {
    <!-- Logo -->
    <div class="navbar-logo">
      @if (navbarSettings()?.leftLogo === 'show') {
        <div class="logo-container clickable" [ngClass]="logoClasses()" [routerLink]="[homeUrl || '/']">
          <img
            [src]="logoSrc()"
            class="logo-image"
            alt="Application Logo"
            [attr.data-is-squarish]="layout.isSquareish()"
            [attr.data-orientation]="layout.windowOrientation()"
            width="{{ logoWidthPixels() }}"
            height="{{ logoHeightPixels() }}"
          />
        </div>
      }
    </div>

    <!-- non logo navbar items -->
    <div class="navbar-non-logo" [ngStyle]="navbarNonLogoStyles()">
      <!-- page items ... menu items  -->
      @if (layout.isGT_medium()) {
        <div class="navbar__page-items hide-scrollbars" [class]="navbarSettings()?.pageItems">
          <ft-horizontal-menu></ft-horizontal-menu>
        </div>
      }

      <!-- filter box - search bar and filter button and notification button -->
      @if (layout.isGT_tiny()) {
        <div class="navbar__filter-box">
          @if (layout.isGT_small() && selectShowSearchBar()) {
            <ft-search-bar class="search-bar"></ft-search-bar>
          }
          @if (!!selectActiveComponentFilter()?.component) {
            @if (isAuthenticated()) {
              <ft-filter-button class="filter-button navigation-component-button"></ft-filter-button>
            }
          }
        </div>
      }

      <!-- right side ... profile info and additional logo -->
      <div class="navbar__right-section">
        <!-- subscribe button -->
        @if (this.stripePublishableKey && !isSubscribed()) {
          <ft-dialog-button
            class="button-component"
            [iconDefinition]="subscriptionService.buttonIcon"
            [buttonText]="layout.isGT_medium() ? subscriptionService.subscribeText() : ''"
            [tooltip]="subscriptionService.subscribeTooltip()"
            [textPosition]="'after'"
            [disabled]="subscriptionService.subscribeButtonDisabled()"
            [clickFunction]="subscriptionService.subscribeDialog()"
          ></ft-dialog-button>
        }

        <!-- Notifications button -->
        @if (this.stripePublishableKey && isSubscribed() && isAuthenticated() && layout.isGT_xsmall()) {
          <ft-dialog-button
            class="button-component"
            [iconDefinition]="notificationService.buttonIcon"
            [buttonText]="layout.isGT_medium() ? notificationService.notificationText() : ''"
            [tooltip]="notificationService.notificationTooltip()"
            [textPosition]="'after'"
            [disabled]="notificationService.notificationButtonDisabled()"
            [clickFunction]="notificationService.notificationDialog()"
          ></ft-dialog-button>
        }

        <!-- contact button -->
        @if (isAuthenticated() && layout.isGT_xsmall()) {
          <ft-dialog-button
            class="button-component"
            [iconDefinition]="contactService.buttonIcon"
            [buttonText]="layout.isGT_medium() ? contactService.buttonText() : ''"
            [tooltip]="contactService.contactTooptip()"
            [textPosition]="'after'"
            [disabled]="contactService.contactButtonDisabled()"
            [clickFunction]="contactService.contactDialog()"
          ></ft-dialog-button>
        }

        <!-- right logo -->
        @if (navbarSettings()?.rightLogo === 'show' && faviconUrl()) {
          <div class="navbar__right-logo">
            {{ navbarSettings()?.rightLogo }}
            <ft-display-logo class="navbar__square-logo" logoType="square" [logoUrl]="faviconUrl()"></ft-display-logo>
          </div>
        }

        <!-- profile button -->
        <div class="navbar__profile" [class]="navbarSettings()?.profile">
          <ft-profile-button [menuItemsGroup]="isAdmin() ? ['admins'] : []"></ft-profile-button>
        </div>

        @if (layout.isLT_large()) {
          <button
            mat-flat-button
            class="collapsed-menu-button"
            aria-label="Collapsed Menu Button"
            #menuTrigger
            [matMenuTriggerFor]="navbarMenu"
          >
            <fa-icon [icon]="faBars"></fa-icon>
          </button>
        }
        <!--     end of right side navbar -->
      </div>
    </div>
    <!-- end of non logo navbar items -->
  }
  <!-- end of authenticated navbar -->

  <!-- right side ... profile info and additional logo -->
</div>

<!-- collapsed menu  -->
<mat-menu
  #navbarMenu="matMenu"
  direction="rtl"
  class="ft-collapsed-menu"
  aria-label="navbar-collapsable-menu"
  panelClass="ft-collapsed-menu-panel"
>
  <ft-vertical-menu
    [menuItems]="menuItems()"
    [caller]="'navbar'"
    itemClass="menu-item indent-wrapped-item"
    [childDepth]="2"
    (navigatedMenuItem)="onMenuItemClick($event)"
  ></ft-vertical-menu>
</mat-menu>
