<!-- <pre>{{ field.fieldGroup | json }}</pre> -->
@if (field && field.fieldGroup && field.fieldGroup.length > 0) {
<div class="ft-output-group">
  <div class="ft-output-heading">
    @if (props.label) {
    <legend>{{ props.label }}</legend>
    }
  </div>
  @for (field of field.fieldGroup; track field; let idx = $index) {
  <div class="ft-output-content">
    <div
      class="ft-row ft-align-items-baseline"
      [ngClass]="props['valueClass'] ? props['valueClass'] : ''"
      *ngTemplateOutlet="
        displayByType;
        context: {
          model: model[idx],
          idx: idx,
          propsType: props.type?.toLowerCase,
          valueClass: props['valueClass']
        }
      "
    ></div>
  </div>
  }
</div>
} @else {
<div class="ft-output-field" [ngClass]="{ 'ft-output-field-flex': props['flex'] }">
  <div class="ft-output-heading">
    @if (props.label) {
    <legend>{{ props.label }}</legend>
    }
  </div>
  <div
    class="ft-output-content"
    [ngClass]="props['valueClass'] ? props['valueClass'] : ''"
    *ngTemplateOutlet="
      displayByType;
      context: { model: model, idx: 0, propsType: props.type?.toLowerCase, valueClass: props['valueClass'] }
    "
  >
    <!-- {{ model }} -->
  </div>
</div>
}
<ng-template #displayByType let-model="model" let-idx="idx" let-propsType="propsType" let-valueClass="valueClass">
  <ng-template
    [ngTemplateOutletContext]="{ model: model, idx: idx, propsType: propsType, valueClass: valueClass }"
    [ngTemplateOutlet]="propsType === 'date' ? dateType : defaultType"
  ></ng-template>
</ng-template>
<ng-template #defaultType let-model="model" let-valueClass="valueClass"
  ><span class="ft-output-text" [ngClass]="props['valueClass'] ? props['valueClass'] : ''">{{
    model
  }}</span></ng-template
>
<ng-template #dateType let-model="model" let-valueClass="valueClass"
  ><span class="ft-output-date" [ngClass]="props['valueClass'] ? props['valueClass'] : ''">{{
    model | date : 'mediumDate'
  }}</span></ng-template
>
