<header class="welcome-header" [ngClass]="hasWelcomeArtefacts() ? 'has-artefact' : 'no-artefact'">
  {{ headerText() }}
</header>

<div class="title-container">
  @if (loginText()) {
    <div class="welcome-title" [innerHTML]="sanitizedLoginText()"></div>
  }

  <div class="welcome-button">
    <ft-login-button></ft-login-button>
  </div>
</div>

<div class="text-container" [ngClass]="hasWelcomeArtefacts() ? 'has-artefact' : 'no-artefact'">
  @if (layoutWelcomeText()) {
    <div class="welcome-text expand-from-half" [innerHTML]="sanitizedWelcomeText()"></div>
  }
  @if (welcomeArtefacts().length) {
    <ft-artefact-article-view
      class="artefact expand-from-half"
      [artefact]="welcomeArtefacts()[0]"
    ></ft-artefact-article-view>
  }
</div>
