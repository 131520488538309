<!-- <pre>{{ field | json }}</pre> -->

<div class="image-upload clickable" [ngStyle]="uploadStyle">
  <ft-magic-image
    [imageSrc]="modelValue"
    [width]="width"
    [height]="height"
    [uploadType]="uploadType"
    [disabled]="readonly()"
    (changedImageSrc)="handleChangedLogo($event, model, field)"
  >
  </ft-magic-image>
</div>
