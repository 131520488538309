<div class="quill-type-component">
  @if (field.props.label) {
  <h2 class="quill-label">{{ field.props.label }}</h2>
  }
  <quill-editor
    class="quill-type-component__editor"
    (onContentChanged)="contentChanged($event)"
    (onEditorCreated)="created($event)"
    [modules]="quillModules"
    [(ngModel)]="htmlText"
    theme="snow"
  ></quill-editor>
</div>
