import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
	APP_ID,
	ApplicationConfig,
	ErrorHandler,
	LOCALE_ID,
	importProvidersFrom,
	inject,
	provideAppInitializer,
	provideEnvironmentInitializer,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withViewTransitions } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
	LuxonDateAdapter,
	LuxonDateModule,
	MAT_LUXON_DATE_ADAPTER_OPTIONS,
	MAT_LUXON_DATE_FORMATS,
} from '@angular/material-luxon-adapter';

/**
 * Formly imports
 */
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyForm, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatMultiCheckboxModule } from '@ngx-formly/material/multicheckbox';

import { AuthInterceptorFn, LoggingInterceptorFn } from '@ft/lib/auth-lib';

import {
	ImageUploaderTypeComponent,
	OutputTypeComponent,
	RepeatTypeComponent,
	dataFilterExtension,
} from '@ft/lib/formly-lib';

import {
	ADMIN_GROUPS,
	APP_NAME,
	AUTH_URL,
	BASE_URL,
	BUILD_DATE,
	DEFAULT_LANGUAGE,
	GRAPHQL_DEFAULT_URI,
	GRAPHQL_PUBLIC_URI,
	HAS_NOTIFICATIONS,
	HOME_URL,
	IGNORED_URLS,
	IS_PRODUCTION,
	LOGIN_CALLBACK,
	LOGIN_URL,
	LOGOUT_CALLBACK,
	LOGOUT_URL,
	ROUTES,
	STRIPE_PK,
	STRIPE_PT,
	USER_GROUPS,
} from '@ft/lib/core-lib';

import { environment } from '../environments/environment';

import { routes } from './app.routes';

import { AppInitService, EnvInitService } from '@ft/lib/init-lib';
import { FT_DefaultLanguage } from '@ft/lib/locale-lib';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppId } from './constants/core';
import { APP_ADMIN_GROUPS, APP_ROUTES, APP_USER_GROUPS } from './constants/menu.constants';

import { OrganisationOptionsComponent } from '@danceShared/organisations-shared/components/formly-organisation-options/formly-organisation-options.component';
import { SyllabusOptionsComponent } from '@danceShared/syllabuses-shared/components/formly-syllabus-options/formly-syllabus-options.component';
import { APP_ENVIRONMENT, WELCOME_TEXT } from '@ft/lib/core-lib';
import { FT_ErrorHandler } from '@ft/lib/error-handler-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { provideApollo } from '@ft/lib/graphql-lib';
import { APP_NAVBAR_SETTINGS, NavbarSettings } from '@ft/lib/models-routing';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { FT_MAT_DATE_FORMATS } from '@furnas-technology/common-library/functions';
import { provideNgxStripe } from 'ngx-stripe';
import { welcomeText } from './constants/welcome-text';

const DefaultLanguage = FT_DefaultLanguage;

const IMPORT_PROVIDERS = [
	BrowserAnimationsModule,
	BrowserModule,
	FontAwesomeModule,
	MatDialogModule,
	FormlyForm,
	LuxonDateModule,
];

const navbarSettings: NavbarSettings = {
	navbarType: 'normal',
	showSearchBar: true,
	hasSidebar: false,
	leftLogo: 'show',
	pageItems: 'show',
	rightLogo: 'hide',
	profile: 'show',
};

// const SHARED_SERVICES = [AuthService, LayoutInfoService, Title];
const SHARED_SERVICES = [LayoutInfoService, GlobalStore];

export const appConfig: ApplicationConfig = {
	providers: [
		/**
		 * Core services
		 */
		provideAnimations(),
		provideAnimationsAsync(),
		provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
		provideHttpClient(withInterceptors([LoggingInterceptorFn, AuthInterceptorFn])),

		/**
		 * Environment settings and constants
		 */
		{ provide: APP_ID, useValue: AppId },
		{ provide: LOCALE_ID, useValue: DefaultLanguage },

		{ provide: APP_ENVIRONMENT, useValue: environment },
		{ provide: APP_NAME, useValue: environment.appName },
		{ provide: AUTH_URL, useValue: environment.authUrl },
		{ provide: BASE_URL, useValue: environment.baseUrl },
		{ provide: HOME_URL, useValue: environment.homeUrl },
		{ provide: LOGIN_CALLBACK, useValue: environment.loginCallback },
		{ provide: LOGOUT_CALLBACK, useValue: environment.logoutCallback },
		{ provide: LOGOUT_URL, useValue: environment.logoutUrl },
		{ provide: LOGIN_URL, useValue: environment.loginUrl },
		{ provide: IGNORED_URLS, useValue: [environment.loginUrl, environment.logoutUrl] },
		{ provide: DEFAULT_LANGUAGE, useValue: DefaultLanguage },
		{ provide: IS_PRODUCTION, useValue: environment.production },
		{ provide: HAS_NOTIFICATIONS, useValue: true },
		{ provide: APP_NAVBAR_SETTINGS, useValue: navbarSettings },
		{ provide: BUILD_DATE, useValue: environment.buildDate },
		{ provide: WELCOME_TEXT, useValue: welcomeText },

		/**
		 * Error handler
		 */
		{ provide: ErrorHandler, useClass: FT_ErrorHandler },

		/**
		 * Date providers
		 */
		{ provide: MAT_DATE_LOCALE, useValue: DefaultLanguage },
		{ provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
		{ provide: LuxonDateAdapter, useValue: { useUtc: false } },
		{ provide: MAT_DATE_FORMATS, useValue: FT_MAT_DATE_FORMATS },

		// { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
		{ provide: MAT_LUXON_DATE_FORMATS, useValue: FT_MAT_DATE_FORMATS },
		{ provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },

		/**
		 * GraphQL
		 */
		{ provide: GRAPHQL_PUBLIC_URI, useValue: environment.graphqlPublicUrl },
		{ provide: GRAPHQL_DEFAULT_URI, useValue: environment.graphdefaultUrl },
		provideApollo(),

		/**
		 * Menu groups and routes
		 */
		{ provide: ADMIN_GROUPS, useValue: APP_ADMIN_GROUPS },
		{ provide: USER_GROUPS, useValue: APP_USER_GROUPS },
		{ provide: ROUTES, useValue: APP_ROUTES },

		/**
		 * Shared services
		 */
		SHARED_SERVICES,

		/**
		 * Apollo
		 */
		provideApollo(),

		/**
		 * Formly
		 */
		importProvidersFrom(
			IMPORT_PROVIDERS,

			// formly imports
			FormlyBootstrapModule,
			FormlyModule.forRoot({
				validationMessages: [{ name: 'required', message: 'This fleld 1s required' }],
				types: [
					{ name: 'repeat', component: RepeatTypeComponent },
					{ name: 'output', component: OutputTypeComponent },
					{ name: 'image-upload', component: ImageUploaderTypeComponent },
					{ name: 'syllabus-options', component: SyllabusOptionsComponent },
					{ name: 'organisation-options', component: OrganisationOptionsComponent },
				],
				extensions: [{ name: 'data-filter-extension', extension: dataFilterExtension }],
			}),
			FormlyMaterialModule,
			FormlySelectModule,
			FormlyMatMultiCheckboxModule,
			// FormlyMatDatepickerModule,
			NgSelectModule,
		),

		/**
		 * Stripe
		 */
		{ provide: STRIPE_PK, useValue: environment.stripePK ?? '' },
		{ provide: STRIPE_PT, useValue: environment.stripePT ?? '' },
		provideNgxStripe(environment.stripePK),

		/***
		 * Environment Initialization
		 */
		provideEnvironmentInitializer(() => inject(EnvInitService).init()),

		/**
		 * App initialization
		 */
		provideAppInitializer(() => inject(AppInitService).init()),
	],
};
