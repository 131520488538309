import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	type OnInit,
	computed,
	effect,
	inject,
	signal,
} from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose, faEnvelope, faMessage, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthStore } from '@ft/lib/auth-lib';
import { ImageUploadService } from '@ft/lib/media-lib';
import { FT_MessageService } from '@ft/lib/messages-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import { ErrorBorderDirective } from '@furnas-technology/angular-library/directives';
import { HumanFileSizePipe } from '@furnas-technology/angular-library/pipes';
import { Base64File, FT_LogError } from '@furnas-technology/common-library/functions';
import {
	NgxUploaderModule,
	type UploadFile,
	type UploadInput,
	type UploadOutput,
	type UploaderOptions,
	humanizeBytes,
} from 'ngx-uploader';
import { type Observable, catchError, from, mergeMap, of, switchMap, take, tap, toArray } from 'rxjs';

const MAX_UPLOAD_BYTES = 6000000;
const EmailPattern = /^\w+@[a-zA-Z0-9_\-]+\.\.[a-zA-Z]{2,5}$/;

@Component({
	selector: 'ft-contact-form',
	templateUrl: './contact-dialog.component.html',
	styleUrls: ['./contact-dialog.component.scss'],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		ErrorBorderDirective,
		FontAwesomeModule,
		FormsModule,
		HumanFileSizePipe,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		NgClass,
		NgxUploaderModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_ContactDialog implements OnInit, AfterViewInit {
	private destroyRef = inject(DestroyRef);
	private formBuilder = inject(UntypedFormBuilder);
	private dialogRef = inject(MatDialogRef);
	protected authStore = inject(AuthStore);

	private msgSvc = inject(FT_MessageService);
	private notifyService = inject(NotifyService);
	private imageUpload = inject(ImageUploadService);

	headerTitle = signal<string>('Contact Us');
	submitTitle = signal<string>('Send');

	name = '';

	formErrors = false;

	controls: string[] = [];

	contactForm = new FormGroup({
		email: new FormControl({ value: this.authStore.email() ?? '', disabled: Boolean(this.authStore.email()) }, [
			Validators.required,
			Validators.email,
			Validators.pattern(EmailPattern),
		]),
		name: new FormControl({ value: this.authStore.fullname() ?? '', disabled: Boolean(this.authStore.fullname()) }, [
			Validators.required,
			Validators.minLength(3),
		]),
		message: new FormControl('', [Validators.required]),
	});

	faEnvelope = faEnvelope;
	faUserCircle = faUserCircle;
	faMessage = faMessage;
	faClose = faClose;

	formErrorMessages = signal('');

	isValidSize = computed<boolean>(() => this.totalSize() <= MAX_UPLOAD_BYTES);
	isValidForm = signal<boolean>(false);

	// upload variables
	maxFiles = signal<number>(3);
	uploadInput: EventEmitter<UploadInput>;
	options: UploaderOptions;
	dragOver: boolean = true;
	MAX_UPLOAD_BYTES = MAX_UPLOAD_BYTES;
	totalSize = computed(() => {
		let size = this.contactForm.get('message')?.value?.length ?? 0;
		for (const file of this.files()) {
			size += file.size;
		}
		return size;
	});

	// formData: FormData;
	files = signal<UploadFile[]>([]);
	numberOfFiles = computed(() => {
		return this.files().length;
	});
	filesNumber = signal<number>(0);

	humanizeBytes: (bytes: number) => string;

	constructor() {
		// check for changes to status
		this.contactForm.statusChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap((x) => {
					this.isValidForm.set(x === 'VALID');
					this.formErrorMessages.set(x);
					if (x === 'INVALID') this.listErrors(this.contactForm);
				}),
			)
			.subscribe();

		// cehck for value changes
		this.contactForm.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap((x) => {
					// nothing to see here
				}),
			)
			.subscribe();

		// file upload settings
		this.options = { concurrency: this.maxFiles(), maxUploads: this.maxFiles(), maxFileSize: MAX_UPLOAD_BYTES };
		this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
		this.humanizeBytes = humanizeBytes;

		effect(() => {
			console.debug(`${this.constructor.name} - files has changed. Current files=${this.files().length}`);
		});
	}

	ngOnInit() {}

	listErrors(formGroup: FormGroup) {
		const allErrors: string[] = [];
		for (const controlName in formGroup.controls) {
			const control = formGroup.get(controlName);
			if (control?.errors && (control.touched || control.dirty)) {
				allErrors.push(`${controlName}: ${JSON.stringify(control.errors)}`);
			}
		}
		this.formErrorMessages.set(allErrors.join());
	}

	ngAfterViewInit() {
		this.controls = Object.keys(this.contactForm.controls);
	}

	onSubmit(): void {
		// Process checkout data here
		try {
			// check values
			const name = this.contactForm.value.name ?? this.contactForm.get('name')?.value ?? '';
			const email = this.contactForm.value.email ?? this.contactForm.get('email')?.value ?? '';
			const message = this.contactForm.value.message ?? this.contactForm.get('message')?.value ?? '';

			const files = this.files()
				.filter((x) => x.nativeFile !== undefined)
				.map((uploadFile: UploadFile) => {
					return uploadFile.nativeFile;
				}) as File[];

			this.convertFilesToBase64(files)
				.pipe(
					take(1),
					switchMap((base64Files) => {
						const attachments = base64Files.map((x) => ({
							name: x.name,
							type: x.type,
							size: x.size,
							b64File: x.b64File,
						}));
						return this.msgSvc
							.sendFeedback({
								subject: `Contact Form ${name} <${email}>`,
								message: message,
								attachments: attachments,
								fromEmail: email,
								fromName: name,
							})
							.pipe(
								tap((sendResult: string) => {
									if (sendResult) {
										this.notifyService.success(`Your message has been sent`, `Thank you!`);
									} else {
										this.notifyService.error(`There was a problem sending your message.\n Your message was not sent`);
									}
								}),
							);
					}),
					catchError((err: unknown) => {
						FT_LogError(err, this.constructor.name, `convertFilesToBase64`);
						return of('');
					}),
				)
				.subscribe();

			this.contactForm.reset();
			this.close();
		} catch (err: unknown) {
			err instanceof Error
				? console.error(`❌ ${this.constructor.name} - unable to send: ${err.message}`)
				: console.error(`❌ ${this.constructor.name} - err=`, err);
		}
	} // end onSubmit

	close() {
		this.dialogRef.close();
	}

	textChanged() {
		// nothing to see here
	}

	getIsInError(formcontrolname: string): boolean {
		const control = this.contactForm.get(formcontrolname);
		if (!control) {
			console.error(`❌ ${this.constructor.name} -  getIsInError: Could not find control name=${formcontrolname}`);
			return false;
		}
		const isInError = !!(control.invalid && control.touched && control.dirty);
		return isInError;
	}

	/**
	 * File upload
	 */
	onUploadOutput(output: UploadOutput): void {
		switch (output.type) {
			case 'allAddedToQueue':
				// uncomment this if you want to auto upload files when added
				// const event: UploadInput = {
				//   type: 'uploadAll',
				//   url: '/upload',
				//   method: 'POST',
				//   data: { foo: 'bar' }
				// };
				// this.uploadInput.emit(event);
				break;
			case 'addedToQueue':
				if (typeof output.file !== 'undefined') {
					this.files.update((values) => {
						return [...values, output.file as UploadFile];
					});

					this.filesNumber.set(this.files().length);
				}
				break;
			case 'uploading':
				if (typeof output.file !== 'undefined') {
					// update current data in files array for uploading file
					const index = this.files().findIndex(
						(file) => typeof output.file !== 'undefined' && file.id === output.file.id,
					);
					this.files()[index] = output.file;
				}
				break;
			case 'removed':
				// remove file from array when removed
				this.files.update((files) => this.files().filter((file: UploadFile) => file !== output.file));
				// this.files = this.files().filter((file: UploadFile) => file !== output.file);
				break;
			case 'dragOver':
				this.dragOver = true;
				break;
			case 'dragOut':
			case 'drop':
				this.dragOver = false;
				break;
			case 'done':
				// The file is downloaded
				break;
		}
	}

	startUpload(): void {
		// const event: UploadInput = {
		//   type: 'uploadAll',
		//   url: 'http://ngx-uploader.com/upload',
		//   method: 'POST',
		//   data: { foo: 'bar' },
		// };
		// this.uploadInput.emit(event);
	}

	cancelUpload(id: string): void {
		this.uploadInput.emit({ type: 'cancel', id: id });
	}

	removeFile(id: string): void {
		this.uploadInput.emit({ type: 'remove', id: id });
	}

	removeAllFiles(): void {
		this.uploadInput.emit({ type: 'removeAll' });
	}

	convertFilesToBase64(files: File[]): Observable<Base64File[]> {
		return from(files).pipe(
			mergeMap((file) => this.imageUpload.convertFileToBase64(file)),
			toArray(),
		);
	}
}
