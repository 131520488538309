<div class="options-component">
  <ng-select
    name="organisationoptions"
    class="select-options"
    [items]="sortedOrganisations()"
    autofocus
    bindLabel="organisationName"
    bindValue="_id"
    [ngModel]="selectedId()"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    (change)="onChange($event)"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="option-card options-component-option">
        <div class="item-name">{{ item.organisationName }}</div>
        <div class="item-addl-info">&nbsp;({{ competitionService.selectOrganisationCount(item._id) }})</div>
        @if(item.organisationLogo) {
        <img
          class="item-logo"
          style="object-fit: contain; height: 28px; width: 32px"
          src="{{ item.organisationLogo }}"
        />
        }
      </div>
    </ng-template>
  </ng-select>

  <ft-add-button addText="Add new organisation" (addClicked)="onCreate()"></ft-add-button>
</div>
