{
	"name": "ft-angular-apps",
	"version": "1.0.1",
	"type": "module",
	"scripts": {
		"ng": "ng",
		"start": "ng serve",
		"build": "ng build",
		"watch": "ng build --watch --configuration development ",
		"test": "wtr test/**/*.test.js --node-resolve",
		"lint": "biome lint ./projects",
		"format": "biome format ./projects --write",
		"check": "biome check ./projects",
		"check:apply": "biome check ./projects --apply",
		"generate-build-date": "echo \"export const BuildDate = '$(date -u)';\" > shared-scripts/build-date.ts",
		"start-furnas-info-app": "npm-run-all clean --parallel lib:watch furnas-info-app:start-waiton",
		"build-furnas-info-app": "run-s lib:build furnas-info-app:build",
		"test-furnas-info-app": "run-p lib:test furnas-info-app:test",
		"lib:build": "ng build @ft/lib --configuration production  ",
		"lib:watch-old": "ng build @ft/lib --watch   --configuration development    ",
		"lib:watch": "node --max-old-space-size=4096 node_modules/@angular/cli/bin/ng build @ft/lib --watch   --configuration development    ",
		"lib:test": "ng test @ft/lib",
		"furnas-info-app:start-waiton": "wait-on dist/mycomp/ui-sdk/package.json && npm run showcase:start",
		"furnas-info-app:start": "ng serve furnas-info-app",
		"furnas-info-app:offline": "ng serve furnas-info-app --configuration offline --watch  ",
		"furnas-info-app:build": "ng build furnas-info-app --configuration production   ",
		"furnas-info-app:watch": "ng build furnas-info-app --watch --configuration development --named-chunks true",
		"furnas-info-app:test": "ng test furnas-info-app",
		"furnas-info-app:bundle-report": "ng build furnas-info-app  --configuration production --named-chunks true  --source-map && source-map-explorer dist/furnas-info-app/browser/*.js",
		"furnas-info-app:stats": "ng build furnas-info-app  --configuration production  --named-chunks true  --stats-json",
		"dancecomps-app:build": "npm run generate-build-date &&  ng build dancecomps-app --configuration production ",
		"dancecomps-app:start": "ng serve dancecomps-app",
		"dancecomps-app:offline": "npm run generate-build-date &&  ng serve dancecomps-app --configuration offline  --watch    ",
		"dancecomps-app:bundle-report": "ng build dancecomps-app  --configuration production  --named-chunks true  --source-map && source-map-explorer dist/dancecomps-app/browser/*.js",
		"dancecomps-app:stats": "ng build dancecomps-app    --named-chunks true  --stats-json",
		"bookings-app:build": "npm run generate-build-date &&  ng build bookings-app --configuration production  ",
		"bookings-app:start": "ng serve bookings-app",
		"bookings-app:offline": "npm run generate-build-date &&  ng serve bookings-app --configuration offline  --watch ",
		"bookings-app:bundle-report": "ng build bookings-app  --configuration production  --named-chunks true  --source-map && source-map-explorer dist/bookings-app/browser/*.js",
		"bookings-app:stats": "ng build bookings-app  --configuration production  --named-chunks true  --stats-json",
		"clean": "rimraf dist"
	},
	"private": true,
	"dependencies": {
		"@angular-devkit/core": "^19.2.0",
		"@angular/animations": "^19.2.0",
		"@angular/cdk": "^19.0.4",
		"@angular/cdk-experimental": "^19.0.4",
		"@angular/common": "^19.2.0",
		"@angular/compiler": "^19.2.0",
		"@angular/core": "^19.2.0",
		"@angular/forms": "^19.2.0",
		"@angular/material": "^19.0.4",
		"@angular/material-luxon-adapter": "^19.0.4",
		"@angular/platform-browser": "^19.2.0",
		"@angular/platform-browser-dynamic": "^19.2.0",
		"@angular/router": "^19.2.0",
		"@angular/youtube-player": "^19.0.4",
		"@apollo/client": "^3.11.4",
		"@fortawesome/angular-fontawesome": "^1.0.0",
		"@fortawesome/fontawesome-svg-core": "^6.6.0",
		"@fortawesome/free-brands-svg-icons": "^6.7.2",
		"@fortawesome/free-regular-svg-icons": "^6.7.2",
		"@fortawesome/free-solid-svg-icons": "^6.7.2",
		"@furnas-technology/angular-library": "^2.2.8",
		"@furnas-technology/common-library": "^6.3.3",
		"@ng-select/ng-select": "^14.2.2",
		"@ngrx/signals": "^19.0.0",
		"@ngrx/store": "^19.0.0",
		"@ngrx/store-devtools": "^19.0.0",
		"@ngx-formly/bootstrap": "^6.3.12",
		"@ngx-formly/core": "^6.3.12",
		"@ngx-formly/material": "^6.3.12",
		"@stripe/stripe-js": "^5.3.0",
		"apollo-angular": "^10.0.3",
		"aws-jwt-verify": "^5.0.0",
		"crypto-js": "^4.2.0",
		"d3": "^7.9.0",
		"d3-org-chart": "^3.1.1",
		"keen-slider": "^6.8.6",
		"ng-table-virtual-scroll": "^1.6.1",
		"ng2-pdf-viewer": "^10.2.2",
		"ngx-cookie-service": "^19.0.0",
		"ngx-device-detector": "^9.0.0",
		"ngx-image-compress": "^18.1.5",
		"ngx-quill": "^27.0.0",
		"ngx-stripe": "^19.0.0",
		"ngx-uploader": "^17.0.1",
		"ol": "^10.3.1",
		"parchment": "^3.0.0",
		"query-string": "^9.1.1",
		"rxjs": "~7.8.1",
		"tslib": "^2.7.0",
		"zone.js": "^0.15.0"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "^19.2.0",
		"@angular/cli": "^19.2.0",
		"@angular/compiler-cli": "^19.2.0",
		"@biomejs/biome": "^1.9.4",
		"@types/crypto-js": "^4.2.2",
		"@types/d3": "^7.4.3",
		"@types/d3-org-chart": "^3.1.2",
		"@types/jasmine": "^5.1.5",
		"@types/luxon": "^3.4.2",
		"@types/openlayers": "^4.6.23",
		"@types/quill": "^2.0.14",
		"@types/web": "^0.0.208",
		"@web/test-runner": "^0.20.0",
		"@web/test-runner-playwright": "^0.11.0",
		"esbuild-visualizer": "^0.7.0",
		"jasmine-core": "^5.2.0",
		"ng-packagr": "^19.0.1",
		"npm-run-all": "^4.1.5",
		"prettier": "^3.4.2",
		"rimraf": "^6.0.1",
		"typescript": "^5.6.3",
		"wait-on": "^8.0.0"
	},
	"peerDependencies": {
		"luxon": "^3.5.0"
	}
}
