import {} from '@angular/common';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type OnInit,
	computed,
	inject,
	viewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { AddButtonComponent } from '@furnas-technology/angular-library/components';

import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { SyllabusService } from '@danceShared/syllabuses-shared/data/syllabus.service';
import { Syllabus } from '@danceShared/syllabuses-shared/syllabus.model';
import { ListItemWidthDirective } from '@furnas-technology/angular-library/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';

type FormlyModelKey = string | number;

@Component({
	selector: 'ft-syllabus-options',
	templateUrl: './formly-syllabus-options.component.html',
	styleUrls: ['./formly-syllabus-options.component.scss'],
	imports: [
		NgSelectModule,
		FormsModule,
		MatFormFieldModule,
		MatButtonModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		ListItemWidthDirective,
		AddButtonComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyllabusOptionsComponent extends FieldType implements OnInit, AfterViewInit {
	destroyRef = inject(DestroyRef);
	syllabusService = inject(SyllabusService);

	competitionService = inject(CompetitionService);

	elemChildren = viewChildren('seloptions');

	faCirclePlus = faCirclePlus;

	typeahead$ = new Subject<string>();

	placeholder = '';
	keyAsString = '';
	required = false;
	touched = false;

	createOption = false;

	selectedIds = computed<string[]>(() => {
		const key: FormlyModelKey = this.key as FormlyModelKey;
		this.keyAsString = String(key);
		const itemKey: string[] = key && this.model[key] ? (this.model[this.key as string] ?? []) : [];
		return itemKey || [];
	});

	sortedSyllabuses = computed(() => {
		const syllabuses = this.syllabusService.store.documents();
		return syllabuses.sort((a, b) =>
			a.syllabusName.toLocaleLowerCase() > b.syllabusName.toLocaleLowerCase() ? 1 : -1,
		);
	});

	constructor() {
		super();
		console.debug(`${this.constructor.name} - constructor - SyllabusOptionsComponent`);
	}

	ngOnInit() {
		this.placeholder = this.field.props?.placeholder ?? '';
		this.required = !!this.field.props?.required;
		this.createOption = !!this.field.props?.['createOption'];

		if (this.required) this.placeholder += ` *`;
	}

	ngAfterViewInit(): void {}

	onChange(syllabuses: Syllabus[] | undefined) {
		const syllabusIds = syllabuses ? syllabuses.map((syllabus) => syllabus._id) : [];
		const key: FormlyModelKey = this.key as FormlyModelKey;
		this.model[key] = syllabusIds;

		// update the form
		const formControl = this.form.get(key as string);
		if (formControl) {
			formControl.setValue(syllabusIds);
			formControl.markAsDirty();
		}
	}

	onCreate() {
		this.syllabusService.editDocument({ id: '', modeType: 'Add New' });
	}
}
