import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable, inject } from '@angular/core';
import { type Observable, map, of } from 'rxjs';

export type ServiceType = 'public' | '';

import { AuthStore } from '@ft/lib/auth-lib';
import { DefaultHeaders, FT_HeaderValue } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { Base64File } from '@furnas-technology/common-library/functions';
import type { UploadFile } from 'ngx-uploader';

const MAX_PAYLOAD = 6000000;

const isUploadFile = (obj: unknown): obj is UploadFile => {
	if (obj !== null && typeof obj === 'object') {
		if (
			Object.keys(obj).includes('nativeFile') &&
			Object.keys(obj).includes('progress') &&
			Object.keys(obj).includes('form') &&
			Object.keys(obj).includes('name') &&
			Object.keys(obj).includes('size') &&
			Object.keys(obj).includes('type')
		) {
			return true;
		}
	}
	return false;
};

export type EmailMessage = {
	subject: string;
	message: string;
	attachments: Base64File[] | null;
	fromName: string;
	fromEmail: string;
};

type EmailBody = {
	subject: string;
	message: string;
	attachments: Base64File[] | null;
	idToken: string;
	fromName: string;
	fromEmail: string;
};

@Injectable({
	providedIn: 'root',
})
export class FT_MessageService {
	httpClient = inject(HttpClient);
	httpBackendModule = inject(HttpBackend);
	authStore = inject(AuthStore);
	environment = inject(EnvironmentService);

	httpBackend: HttpClient;

	baseUrl: string = '';

	constructor() {
		this.httpBackend = new HttpClient(this.httpBackendModule);
		this.baseUrl = this.environment.baseUrl();
	}

	createHttpHeaders(additionalHeaders?: FT_HeaderValue): HttpHeaders {
		const headers = new HttpHeaders({ ...DefaultHeaders });
		if (additionalHeaders) {
			for (const hdr of Object.entries(additionalHeaders)) {
				const key = hdr[0];
				const value = hdr[1] as string | string[];
				headers.set(key, value);
			}
		}

		return headers;
	}

	sendFeedback(emailMessage: EmailMessage): Observable<string> {
		console.debug(`${this.constructor.name} - sendFeedback - emailMessage=`, emailMessage);
		const url = `${this.baseUrl}feedback`;

		// attachments
		const body: EmailBody = {
			subject: emailMessage.subject,
			message: emailMessage.message,
			attachments: emailMessage.attachments,
			idToken: this.authStore.selectIdToken(),
			fromName: emailMessage.fromName,
			fromEmail: emailMessage.fromEmail,
		};

		const postMessage$ = JSON.stringify(body).length > MAX_PAYLOAD ? of('') : this.postMessage(url, body);
		return postMessage$;
	}

	postMessage(url: string, body: EmailBody): Observable<string> {
		const http = this.httpClient;
		const headers = new HttpHeaders({ ...DefaultHeaders });

		try {
			return http.post<{ MessageId: string | number }>(url, body, { headers: headers }).pipe(
				map((result) => {
					console.log(`Thank you for the message! 👋`);
					console.debug(`${this.constructor.name} - result=`, result);
					if (result?.MessageId) {
						return String(result.MessageId);
					}
					return '';
				}),
			);
		} catch (err: unknown) {
			return of('');
		}
	} // end postMessage
} // end service class
