import { Injectable, computed, inject } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AuthStore } from '@ft/lib/auth-lib';
import { CommonDialogService } from '@ft/lib/dialogs-lib';
import { FT_ContactDialog } from './contact-dialog/contact-dialog.component';

@Injectable({ providedIn: 'root' })
export class ContactService {
	private dialogService = inject(CommonDialogService);
	private authStore = inject(AuthStore);

	buttonIcon = faEnvelope;

	/**
	 * Contact button
	 */
	tooltipLoggedIn = `Send comments and upload documents to be included on the site`;
	tooltipNotLoggedIn = `You must sign up and log in to be able to send feedback and documents`;

	contactTooptip = computed(() => {
		return this.authStore.isAuthenticated() ? this.tooltipLoggedIn : this.tooltipNotLoggedIn;
	});

	buttonText = computed(() => {
		return 'Contact';
	});

	contactDialog = computed(() => {
		return () => this.openFeedbackDialog();
	});

	contactButtonDisabled = computed(() => {
		return !this.authStore.isAuthenticated();
	});

	constructor() {}

	sendFeedback(summary: string, description: string, formData: FormData) {
		// const headers = new HttpHeaders({
		//     'Authorization': `Bearer ${this.browserStorage.getAccessToken()}`,
		//      enctype: 'multipart/form-data',
		//   });
		//   formData.append("summary", summary);
		//   if(description){
		//     formData.append("description", description);
		//   }
		// return this.apiService.post('/create-issue', formData, headers);
	}

	openFeedbackDialog() {
		this.dialogService.openDialog(FT_ContactDialog, { panelClass: 'rounded-xlarge', data: { closeOthers: false } });
	}
}
