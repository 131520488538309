<div
  class="ft-app-base-layout hide-scrollbars container element-transition count"
  [ngClass]="[sidbarIsHidden() ? 'sidebar-minimised' : '', loggedInOutClass()]"
  [class.shimmer-background]="authInProgress()"
  [attr.data-auth]="isAuthenticated() ? 'true' : 'false'"
  [attr.data-auth-in-progress]="authInProgress() ? 'true' : 'false'"
  [attr.data-show-login]="showlogin() ? 'true' : 'false'"
  resizeObserver
  (resize)="onResize($event)"
>
  @if (showlogin()) {
    <ft-welcome></ft-welcome>
  } @else {
    <!-- <ng-container> -->
    <ft-app-navbar></ft-app-navbar>

    <!-- content -->

    <div
      class="app-base-content"
      [ngClass]="loggedInOutClass()"
      [class.shimmer-background]="authInProgress()"
      backgroundImage
      [imageUrl]="baseBackground()"
      [imageSize]="backgroundParams().imageSize"
      [imageRepeat]="backgroundParams().imageRepeat"
    >
      @if (gss.selectNavbarSettings()?.hasSidebar) {
        <ft-app-sidebar></ft-app-sidebar>
      }

      <div class="app-base-page">
        <router-outlet #baseLayoutOutlet="outlet"></router-outlet>

        @if (!baseLayoutOutlet.isActivated) {
          <div class="default-base-page"></div>
        }
      </div>
    </div>
  }
</div>
