<!-- Horizontal Menu -->

@for (menuItem of menuItems(); track menuItem.menuTitle; let oddRow = $odd) {
  @if (menuItem.children.length) {
    <button
      mmat-raised-button
      [matMenuTriggerFor]="childMenu"
      (click)="setChildrenMenu(menuItem.children, menuTrigger, menuItem)"
      #menuTrigger="matMenuTrigger"
      [class.is-active]="topRoutePath() === menuItem.menuPath[0]"
      class="nav-button has-children clickable"
      (menuClosed)="onMenuClosed(menuTrigger)"
      [attr.data-path]="menuItem.menuPath"
    >
      <div class="nav-icon" [matTooltip]="showTooltip() ? menuItem.menuTitle : ''">
        <fa-icon [icon]="menuItem.menuIcon" class="fa-lg nav-icon" [rotate]="" [ngClass]="itemTextClass()"></fa-icon>
      </div>
      <div class="nav-text" [ngClass]="itemTextClass()">
        {{ menuItem.menuTitle }}
      </div>
    </button>
  } @else {
    <button
      mat-raised-button
      [routerLink]="menuItem.menuPath"
      routerLinkActive="is-active"
      class="nav-button no-children clickable"
      [attr.data-path]="menuItem.menuPath"
    >
      <div class="nav-icon" [matTooltip]="showTooltip() ? menuItem.menuTitle : ''">
        <fa-icon [icon]="menuItem.menuIcon" class="fa-lg nav-icon" [rotate]="" [ngClass]="itemTextClass()"></fa-icon>
      </div>
      <div class="nav-text" [ngClass]="itemTextClass()">
        {{ menuItem.menuTitle }}
      </div>
    </button>
  }
}

<!-- Shared child menu for desktop -->
<mat-menu #childMenu="matMenu">
  <ft-vertical-menu
    [menuItems]="selectedChildren()"
    [caller]="this.selectedParentTitle()"
    itemClass="child-menu-item indent-wrapped-item"
    (navigatedMenuItem)="onMenuItemClick($event)"
  ></ft-vertical-menu>
</mat-menu>
