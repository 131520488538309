import { Routes } from '@angular/router';

import { LoginRoute, LogoutRoute, UnsubscribeRoute } from '@ft/lib/auth-user-lib';
import { PrivacyRoute, TermsRoute } from '@ft/lib/pages-lib';
import { APP_ROUTES } from './constants/menu.constants';

export const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },

	LoginRoute,
	LogoutRoute,
	PrivacyRoute,
	TermsRoute,
	UnsubscribeRoute,

	...APP_ROUTES,

	{ path: '**', redirectTo: 'home' },
];
