import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { type PasteDialogData, PasteTextRowsComponent } from '@ft/lib/components';
import { FieldArrayType, FormlyFieldConfig, type FormlyFieldProps, FormlyModule } from '@ngx-formly/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectModule } from '@angular/material/select';
import { faClone, faFileLines, faSquareMinus } from '@fortawesome/free-solid-svg-icons';
import { getMatchingSearchBarFields } from '@ft/lib/active-filter-lib';
import { SearchBarComponent } from '@ft/lib/filter-searchbar';
import {
	FilterByFields,
	FilteredRecord,
	SearchBarField,
	SearchStringToFieldFilters,
} from '@furnas-technology/common-library/filters';
import { FormlySelectModule } from '@ngx-formly/core/select';

type FTRepeatFieldConfig = FormlyFieldConfig<
	(FormlyFieldProps & { [additionalProperties: string]: unknown }) | undefined
>;

type ParsedKey = string | number | (string | number)[] | undefined;
type ParsedRow = Record<string, unknown>;

const hasFieldGroup = (obj: unknown): obj is FormlyFieldConfig => {
	return typeof obj === 'object' && obj !== null && 'fieldGroup' in obj;
};
@Component({
	selector: 'ft-repeat-section',
	templateUrl: './repeat-type.component.html',
	styleUrls: ['./repeat-type.component.scss'],
	imports: [
		FontAwesomeModule,
		FormlyModule,
		FormlySelectModule,
		MatDialogModule,
		MatSelectModule,
		MatTooltipModule,
		SearchBarComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatTypeComponent extends FieldArrayType {
	private destroyRef = inject(DestroyRef);
	protected dialog = inject(MatDialog);

	searchTerm: string = '';
	_model = this.model;

	faFileLines = faFileLines;
	faSquareMinus = faSquareMinus;
	faClone = faClone;

	ngOnInit() {
		this._model = this.model;
	}

	customAdd() {
		this.add();
	}

	handleClone(rowIndex: number, fieldOfGroup: FormlyFieldConfig) {
		if (!this.field?.fieldGroup || this.field.fieldGroup?.length < 1) return;

		const newRowIndex = rowIndex + 1;
		this.add(newRowIndex, this.model[rowIndex]);
		const clonedRow = this.field.fieldGroup[newRowIndex];
		clonedRow.className += ` ft-duplicated`;
	}

	handleEscapeKeyPress() {}

	handleSearch(term: string) {
		console.debug(`${this.constructor.name} - handleSearch - searchTerm=${term}`);
		this.searchTerm = term;
		this.performSearch(term);
	}

	performSearch<T>(term: string) {
		// if not group or model, exit out of here
		if (!this.field.fieldGroup) return;
		if (!this.field.model) return;

		// get searchFields - exit if none specified ?? should we take all if none specified?
		const searchFields: string[] = this.field.props?.['searchFields'] ?? [];
		if (!Array.isArray(searchFields) || !searchFields.length) return;

		const searchBarFields: SearchBarField<T>[] = [];
		for (const field of searchFields) {
			searchBarFields.push(new SearchBarField<T>(field, ['string']));
		}

		// const searchFields = this.field.props[searchFieldName];
		const fieldGroup: FTRepeatFieldConfig[] = this.field.fieldGroup;

		/**
		 * Create list of matching fields for searchBarFilters
		 */
		const searchString = term;
		const searchBarFilters = SearchStringToFieldFilters<T>(searchString, 'string');
		for (const searchBarFilter of searchBarFilters) {
			const matchingSearchBarFields = getMatchingSearchBarFields<T>(searchBarFields, searchBarFilter.filterDataType);
			searchBarFilter.fieldnames = matchingSearchBarFields;
		}

		console.debug(`${this.constructor.name} - handleSearch - searchTerm=${term}, searchBarFilters=`, searchBarFilters);

		for (let i = 0; i < fieldGroup?.length; i++) {
			const fieldGroupI: FTRepeatFieldConfig = fieldGroup[i];
			const row: Record<string, unknown> = { ...(fieldGroupI.model || {}) };
			console.debug(`${this.constructor.name} - handleSearch - row=`, row);

			const filteredRecord: FilteredRecord<T> = {
				selected: null,
				omitted: null,
				record: row as T,
			};

			// if (searchBarFilters.length && this.filterStore.showSearchBar()) {
			FilterByFields(filteredRecord, searchBarFilters);
			// }

			if (filteredRecord.omitted) {
				fieldGroupI.hide = true;
				fieldGroupI.resetOnHide = false;
			} else {
				fieldGroupI.hide = false;
			}
		} // end for Model
	} // end performSearch

	handlePasteRows(): void {
		const parseKeys = this.keyList();

		const dialogModel: PasteDialogData = {
			title: `Paste Person Rows`,
			label: '',
			placeHolder: `Paste text here`,
			rows: [],
			fieldList: parseKeys.map((x) => String(x)),
		};

		const dialogRef = this.dialog.open<PasteTextRowsComponent, PasteDialogData>(PasteTextRowsComponent, {
			data: dialogModel,
			panelClass: 'ft-paste-panel',
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result: string[]) => {
				if (!result) return;
				if (result?.length === 0) return;

				const parseKeys = this.keyList();
				const newRows = this.parseRows(result, parseKeys);
				let newRowIndex = this.field.fieldGroup?.length ?? 0;
				for (const newRow of newRows) {
					this.add(newRowIndex, newRow);
					newRowIndex += 1;
				}

				// this.parseAndAddRows(result);
			});
	}

	parseRows(rows: string[], fieldNames: ParsedKey[]): ParsedRow[] {
		if (!rows || rows?.length === 0) return [];

		const newRows: ParsedRow[] = [];

		// loop through each row
		for (const row of rows) {
			if (row) {
				const rowValues: string[] = row.split(',');

				const sValues = rowValues.map((value) => {
					if (value && value?.length > 2 && value.startsWith(`"`) && value.endsWith(`"`)) {
						const tValue = value.slice(1).slice(-1);
						return tValue;
					} else {
						return String(value).trim();
					}
				});

				const lastIndex = sValues?.length - 1;
				const newRow: ParsedRow = {};

				for (let i = 0; i <= fieldNames?.length; i++) {
					const fieldName = fieldNames[i];
					if (!fieldName) continue;
					if (lastIndex >= i) {
						newRow[fieldName as string] = sValues[i] ?? '';
					} else {
						newRow[fieldName as string] = '';
					}
				}

				newRows.push(newRow);
			} // end if row
		}

		return newRows;
	}

	keyList(): ParsedKey[] {
		const fieldArray = this.field?.fieldArray;
		if (!fieldArray || !hasFieldGroup(fieldArray)) return [];

		const fieldGroup = (fieldArray as FormlyFieldConfig).fieldGroup;
		if (!fieldGroup) return [];
		const parseKeys = fieldGroup.map((group) => group.key).filter((x) => x !== undefined);
		return parseKeys;
	}
} // end class
