import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { MagicImageComponent } from '@ft/lib/media-lib';
import { FT_getNestedValue, UploadType } from '@furnas-technology/common-library/functions';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/core';

type FTRepeatFieldConfig = FormlyFieldConfig<
	(FormlyFieldProps & { [additionalProperties: string]: unknown }) | undefined
>;

@Component({
	selector: 'ft-formly-image',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss'],
	imports: [NgStyle, MagicImageComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderTypeComponent extends FieldType {
	destroyRef = inject(DestroyRef);

	uploadedFileUrl?: string;
	modelKey!: string;
	modelValue: string = '';

	uploadType: UploadType = 'image';
	uploadStyle = {};

	height = '100%';
	width = '100%';

	readonly = signal<boolean>(false);

	ngOnInit() {
		this.modelKey = String(this.key);
		this.modelValue = this.modelKey.includes('.')
			? FT_getNestedValue(this.model, this.modelKey)
			: this.model[this.modelKey];

		if (this.props['height']) this.height = this.props['height'];
		if (this.props['width']) this.width = this.props['width'];

		const maxHeight = this.props['maxHeight'] ? { 'max-height': this.props['maxHeight'] } : {};
		const maxWidth = this.props['maxWidth'] ? { 'max-width': this.props['maxWidth'] } : {};
		this.readonly.set(!!this.props['readonly']);

		this.uploadStyle = Object.assign(
			{
				width: this.width,
				height: this.height,
				border: `1px solid rgba(100,100,100,0.1)`,
			},
			maxHeight,
			maxWidth,
		);

		// default to image if uploadType not passed
		if (this.props['uploadType']) {
			this.uploadType = this.props['uploadType'];
		} else {
			this.uploadType = 'image';
		}
	}

	handleEscapeKeyPress() {
		console.debug(`${this.constructor?.name} - handleEscapeKeyPress`);
	}

	handleChangedLogo(logo: string, model: unknown, field: FormlyFieldConfig): void {
		console.debug(`${this.constructor.name} - handleChangedLogo - logo.length=${logo ? logo.length : 0}`);

		this.model[this.key as string] = logo;
		this.field.formControl?.markAsDirty();
	}
}
