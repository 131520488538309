<div class="ft-dialog-component" id="contact">
  <!-- card header  -->
  <div mat-dialog-title>
    <div class="title">
      <fa-icon [icon]="faMessage"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="form-group">
      <!-- name -->
      <div class="form-row">
        <mat-form-field class="name-field">
          <mat-label>Name <fa-icon [icon]="faUserCircle"></fa-icon></mat-label>
          <input matInput type="text" name="name" class="form-control" placeholder="Name" formControlName="name" />
        </mat-form-field>

        <!-- email -->
        <mat-form-field class="email-field">
          <mat-label>Name <fa-icon [icon]="faEnvelope"></fa-icon></mat-label>
          <input matInput type="email" name="email" class="form-control" placeholder="E-mail" formControlName="email" />
        </mat-form-field>
      </div>

      <!-- message -->
      <div class="contact-message-container" [errorBorder]="getIsInError('message')">
        <textarea
          matInput
          type="text"
          class="form-control contact-message"
          [ngClass]="{ 'has-error': getIsInError('message') }"
          name="message"
          placeholder="Your Message"
          formControlName="message"
          rows="10"
        ></textarea>
      </div>
    </form>

    <!-- UPLOAD START -->
    <div class="upload-box">
      <div
        class="drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        [ngClass]="{ 'no-files': numberOfFiles() === 0 }"
      >
        <p class="files-header">
          Drag files here or
          <label class="upload-button">
            <input
              type="file"
              ngFileSelect
              style="display: none"
              [options]="options"
              (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput"
              [disabled]="numberOfFiles() >= 3"
              multiple
            />
            Click Here
          </label>
          to upload (
          @if (numberOfFiles() > 0) {
            {{ numberOfFiles() }} of {{ maxFiles() }}
          } @else {
            {{ maxFiles() }} max
          }
          )
        </p>
        <p class="upload-note">
          @if (numberOfFiles() > 0 && !isValidSize()) {
            <div class="size-error">Current size of {{ totalSize() | humanFileSize }} exceeds limit</div>
          } @else {
            Note: Max attachment size 6MB. Cumulative size cannot exceed 6MB
          }
        </p>
        <!-- FILES LIST BEG -->
        @if (numberOfFiles() > 0) {
          <div class="files-list">
            <div>
              @for (f of files(); track i; let i = $index) {
                @if (!!f) {
                  <div class="upload-item">
                    <div class="upload-item-content">
                      <div class="filename">
                        <div><fa-icon [icon]="faClose" class="clickable" (click)="removeFile(f.id)"></fa-icon></div>
                        <span>{{ f.name }} ({{ f.size | humanFileSize }})</span>
                      </div>
                      @if (f?.progress?.data && (f?.progress?.data?.percentage ?? 0 > 0)) {
                        <div class="progress-content">
                          <div class="progress">
                            <span
                              class="bar"
                              [style.width]="f.progress.data?.percentage + '%'"
                              [class.is-done]="f.progress.data?.percentage === 100"
                            ></span>
                          </div>
                        </div>

                        <div class="progress-text-content">
                          <span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100">
                            <span>{{ f.progress.data?.percentage }}%</span>
                            @if (f.progress.data?.percentage !== 100) {
                              <span>&nbsp;Uploading...</span>
                            }
                            @if (f.progress.data?.percentage === 100) {
                              <span>Done</span>
                            }
                          </span>
                          @if (f.progress.data?.percentage !== 0 && f.progress.data?.percentage !== 100) {
                            <span class="speed-and-eta-text">
                              <span>{{ f.progress.data?.speedHuman }}</span>
                              <span>ETA {{ f.progress.data?.etaHuman }}</span>
                            </span>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        }
        <!-- FILES LIST END -->
      </div>
    </div>

    <!-- UPLOAD END -->
  </mat-dialog-content>

  <!-- card actions  -->

  <mat-dialog-actions>
    <div class="button-row">
      <button
        type="submit"
        mat-button
        class="submit-button"
        (click)="onSubmit()"
        [disabled]="!isValidForm() || !isValidSize()"
        aria-label="Submit"
      >
        {{ submitTitle() }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
