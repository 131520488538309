import { DefaultSelectedId, SelectedId } from '@ft/lib/core-lib';

import { APP_ID, computed, inject } from '@angular/core';
import { Artefact } from '@ft/lib/artefacts-lib';
import { IS_PRODUCTION } from '@ft/lib/core-lib';
import { DataService } from '@ft/lib/data-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { GenericIndexedDbService, IndexedDBFactoryService, StoreDefinition } from '@ft/lib/indexeddb-lib';
import { isHas_Id } from '@ft/lib/signal-store-lib';
import { UPDATE_STATUS, updateStateIsProcessing } from '@furnas-technology/angular-library';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import {
	patchState,
	signalStore,
	type,
	watchState,
	withComputed,
	withHooks,
	withMethods,
	withState,
} from '@ngrx/signals';
import {
	entityConfig,
	removeAllEntities,
	removeEntity,
	setEntities,
	setEntity,
	withEntities,
} from '@ngrx/signals/entities';
import { catchError, finalize, map, of, take, tap } from 'rxjs';
import { BackgroundImage } from '../data/background.constants';

type BackgroundsStoreState = {
	updateStatus: UPDATE_STATUS;
	loadStatus: UPDATE_STATUS;
	indexLoaded: boolean;
	error: string | null;
	selectedId: SelectedId;
	backgroundImage: string;

	background: string; // 1920x1080
	icon: string; // 120x120
	iconImage: string;
	logoLandscape: string;
	logoPortrait: string;
	logoSquare: string;
	logoLandscapeTransparent: string; // 320x180
	logoSquare512: string; // 512x512
	logoText: string; // 320x180
};

const initialState: BackgroundsStoreState = {
	updateStatus: UPDATE_STATUS.blank,
	loadStatus: UPDATE_STATUS.blank,
	indexLoaded: false,
	error: null,
	selectedId: DefaultSelectedId,
	backgroundImage: '',

	background: '',
	icon: '',
	iconImage: '',
	logoLandscape: '',
	logoPortrait: '',
	logoSquare: '',
	logoLandscapeTransparent: '',
	logoSquare512: '',
	logoText: '',
};

const signalStoreName = 'BackgroundStore';

const BACKGROUND_STORE: StoreDefinition = {
	tableName: `Background`,
	keyPath: `_id`,
	autoIncrement: false,
	indexFields: `_id`,
};

const indexedDBConfig = {
	indexedDBName: 'backgrounds',
	indexedDBVersion: 1.5,
	indexedDBStoreList: [BACKGROUND_STORE],
};
let indexDbService: GenericIndexedDbService | undefined;

const _entityConfig = entityConfig({
	entity: type<BackgroundImage>(),
	collection: '',
	selectId: (record: BackgroundImage) => record._id,
});

const removeDuplicates = (imageArray: BackgroundImage[]): BackgroundImage[] => {
	let index = 0;
	const unique = new Set(imageArray.map((item) => item.url));
	const filtered = Array.from(unique);
	return filtered.map((x) => ({ url: x, _id: `${index++}` }));
};

export const BackgroundStore = signalStore(
	{ protectedState: true, providedIn: 'root' },
	withEntities(_entityConfig),
	withState<BackgroundsStoreState>(initialState),
	withComputed((store) => ({
		documents: computed(() => store.Entities()),
	})),
	withMethods((store) => ({
		updateLoadStatus(loadStatus: UPDATE_STATUS): void {
			patchState(store, { loadStatus: loadStatus });
		},
	})),

	/**
	 * primary selects
	 */
	withMethods(({ EntityMap }) => ({
		selectDocumentById(id: string) {
			return EntityMap()[id] ?? undefined;
		},
	})),

	/**
	 * entity methods
	 */
	withMethods((store) => ({
		upsertDocument(document: BackgroundImage) {
			const allDocuments = store.Entities();
			if (allDocuments.findIndex((item) => item.url === document.url) === -1) {
				document._id = (allDocuments.length + 1).toString();
				patchState(store, setEntity(document, _entityConfig));
				// update indexed db
				if (indexDbService) {
					console.debug(`${signalStoreName} - upsertDocument - into idb ${indexDbService.indexedDbName()}`);
					indexDbService.upsertData(indexDbService.tableName(), document);
				}
			}
		},
		upsertDocuments(documents: BackgroundImage[]) {
			const uniqueDocuments = removeDuplicates(documents);
			patchState(store, setEntities(uniqueDocuments, _entityConfig));
			// update indexed db
			if (indexDbService) {
				console.debug(`${signalStoreName} - upsertDocuments - into idb ${indexDbService.indexedDbName()}`);
				indexDbService.upsertData(indexDbService.tableName(), documents);
			}
		},
		removeDocument(id: string) {
			console.debug(`${signalStoreName} - removeDocument - id=${id}`);
			patchState(store, removeEntity(id, _entityConfig));
			// update indexed db
			if (indexDbService) {
				indexDbService.deleteData(indexDbService.tableName(), id).subscribe();
			}
		},
		clearDocuments() {
			patchState(store, removeAllEntities(_entityConfig));
			// update indexed db
			if (indexDbService) {
				indexDbService.clearStore(indexDbService.tableName()).subscribe();
			}
		},

		setSelectedId(id: string) {
			patchState(store, { selectedId: { id: id, timestamp: new Date().getTime() } });
		},
	})),

	withMethods((store, dataService = inject(DataService), env = inject(EnvironmentService)) => ({
		/**
		 * Load documents from environment and from API
		 */
		loadAllDocuments(): void {
			console.debug(`${signalStoreName} - loadAllDocuments`);
			patchState(store, { loadStatus: UPDATE_STATUS.processing });

			const backgroundsUrl = env.backgroundsUrl();
			if (backgroundsUrl) {
				console.debug(`${signalStoreName} - loadAllDocuments - backgroundsUrl=${backgroundsUrl}`);
				dataService
					.getPublic<{ backgroundArtefacts: Artefact[] }>(backgroundsUrl)
					.pipe(
						take(1),
						map((data) => {
							console.debug(`${signalStoreName} - loadAllDocuments - background data=`, data);
							const backgroundImages = data?.backgroundArtefacts ? data.backgroundArtefacts : ([] as Artefact[]);
							const images: BackgroundImage[] = [];
							let index = 0;
							for (const artefact of backgroundImages) {
								if (artefact.artefactImages.length) {
									for (const image of artefact.artefactImages) {
										images.push({ _id: `${index++}`, url: image });
									}
								}
							}

							return images;
						}),
						catchError((err: unknown) => {
							console.error(`❌ getWelcomeBackgroundImages - error:`, err);
							return of([] as BackgroundImage[]);
						}),
					)
					.subscribe((records) => {
						store.upsertDocuments(records);
						patchState(store, { loadStatus: UPDATE_STATUS.loaded });
					});
			} else {
				patchState(store, { loadStatus: UPDATE_STATUS.loaded });
			}
		},

		addBackgroundImage(imageUrl: string | string[]): void {
			const imageUrls = Array.isArray(imageUrl) ? imageUrl : [imageUrl];

			for (const imageUrl of imageUrls) {
				store.upsertDocument({ url: imageUrl, _id: '' });
			}
		},

		setBackgroundImage(imageUrl: string) {
			patchState(store, { backgroundImage: imageUrl });
		},
	})),

	withComputed(({ documents }) => ({
		documentsLoaded: computed(() => documents().length),
	})),
	withComputed((store) => ({
		isLoading: computed(
			() => !!(updateStateIsProcessing(store.loadStatus()) || updateStateIsProcessing(store.updateStatus())),
		),
		isLoaded: computed(() => (['success', 'loaded', 'failed'] as UPDATE_STATUS[]).includes(store.loadStatus())),

		selectAllDocuments: computed(() => store.Entities()),

		selectedDocument: computed(() =>
			store.Entities().find((document) => isHas_Id(document) && document._id === store.selectedId().id),
		),

		sortedDocuments: computed(() => {
			if (!store.documents().length) return [];
			const firstDocument = store.documents()[0];
			return store.documents();
		}),

		selectBackgroundImage: computed(() => {
			return store.backgroundImage();
		}),

		selectIcon: computed(() => {
			return store.icon();
		}),

		selectIconImage: computed(() => {
			return store.iconImage();
		}),

		selectLogoLandscape: computed(() => {
			return store.logoLandscape();
		}),

		selectLogoPortrait: computed(() => {
			return store.logoPortrait();
		}),

		selectLogoSquare: computed(() => {
			return store.logoSquare();
		}),

		selectLogoLandscapeTransparent: computed(() => {
			return store.logoLandscapeTransparent();
		}),

		selectLogoSquare512: computed(() => {
			return store.logoSquare512();
		}),

		selectLogoText: computed(() => {
			return store.logoText();
		}),
	})),

	withHooks({
		onInit(store) {
			const appId = inject(APP_ID);
			const isProduction = inject(IS_PRODUCTION);
			const isDev = !isProduction;

			if (isDev) {
				console.debug(`${signalStoreName} - onInit - appId=${appId}`);
			}

			/**
			 * Indexed DB
			 */
			indexDbService = inject(IndexedDBFactoryService).createIndexedDBService(indexedDBConfig);

			/**
			 * Load from environment
			 */
			const env = inject(EnvironmentService);
			const environment = env.environment();
			patchState(store, {
				backgroundImage: environment.background,
				icon: environment.icon,
				iconImage: environment.icon,
				logoLandscape: environment.logoLandscape,
				logoPortrait: environment.logoPortrait,
				logoSquare: environment.logoSquare,
				logoLandscapeTransparent: environment.logoLandscapeTransparent,
				logoSquare512: environment.logoSquare512,
				logoText: environment.logoText,
			});

			if (isDev) {
				console.debug(`${signalStoreName} - onInit - loadedEnvironment documents, store=`, store);
			}

			/**
			 * If indexed DB service, then initial load from indexed DB before loading from API
			 */
			if (indexDbService) {
				console.debug(`${signalStoreName} - indexDbService - hello=${indexDbService.hello()}`);

				indexDbService
					.getAllRecords<BackgroundImage>(indexDbService.tableName())
					.pipe(
						take(1),
						tap((idxResponse) => {
							const records = idxResponse.records ?? ([] as BackgroundImage[]);
							console.debug(`${signalStoreName} - onInit - loadFromIndex - tap - index records=`, records);
							store.upsertDocuments(records as BackgroundImage[]);
						}),
						finalize(() => {
							console.debug(`${signalStoreName} - onInit - loadFromIndex - FINALIZE - calling loadAllDocuments`);
							store.loadAllDocuments();
						}),
						catchError((err: unknown) => {
							FT_LogError(err, signalStoreName, `onInit - loadFromIndex`);
							return of(null);
						}),
					)
					.subscribe();
			} else {
				console.debug(`${signalStoreName} - onInit - no indexDbService`);
				store.loadAllDocuments();
			}

			/**
			 * set watch state
			 */
			watchState(store, (state) => {
				if (isDev) {
					console.log(`${signalStoreName} - [watchState] counter isLoading=${store.isLoading()}, state`, state);
				}
			}); // logs state changes
		},
	}),
);
