@for (menuItem of menuItems(); track menuItem.menuTitle; let oddRow = $odd) {
  <button
    (click)="$event.stopPropagation(); onClick(menuItem)"
    mat-menu-item
    [routerLink]="menuItem.menuPath"
    routerLinkActive
    #rla="routerLinkActive"
    class="nav-button"
    [attr.data-menuPath]="menuItem.menuPath.join('/')"
    [ngClass]="[itemClass(), rla.isActive ? 'is-active' : '']"
  >
    <fa-icon
      [icon]="menuItem.menuIcon"
      class="fa-lg nav-icon"
      [rotate]=""
      [ngClass]="rla.isActive ? 'is-active' : ''"
    ></fa-icon>
    <span class="nav-text" [ngClass]="rla.isActive ? 'is-active' : ''">
      {{ menuItem.menuTitle }}
    </span>

    @if (childDepth() > currentDepth() && menuItem.children.length) {
      <ft-vertical-menu
        [menuItems]="menuItem.children"
        [caller]="menuItem.menuTitle"
        [itemClass]="itemClass()"
        [childDepth]="childDepth()"
        [currentDepth]="currentDepth() + 1"
        (navigatedMenuItem)="onClick($event)"
      ></ft-vertical-menu>
    }
  </button>
}
