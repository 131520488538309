import { Route } from '@angular/router';

export const PrivacyRoute: Route = {
	path: 'privacy',
	pathMatch: 'prefix',
	loadComponent: () => import('./privacy/privacy.component').then((mod) => mod.PrivacyComponent),
};

export const TermsRoute: Route = {
	path: 'terms',
	pathMatch: 'prefix',
	loadComponent: () => import('./terms/terms.component').then((mod) => mod.TermsComponent),
};

// export const TermsRoute: Route = {
//   path: 'terms',
//   pathMatch: 'prefix',
//   loadComponent: () => import('./logout/logout.component').then((mod) => mod.LogoutComponent),
// };

// export const DataRoute: Route = {
//   path: 'data',
//   pathMatch: 'prefix',
//   loadComponent: () => import('./logout/logout.component').then((mod) => mod.LogoutComponent),
// };
