import { Injectable, inject } from '@angular/core';
import { type Observable, catchError, finalize, map, of, switchMap } from 'rxjs';

import { AuthService, AuthStore, FT_CognitoVerifier } from '@ft/lib/auth-lib';
import { CognitoUrls } from '@ft/lib/core-lib';
import { DataService } from '@ft/lib/data-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { ImageStoreService } from '@ft/lib/media-lib';
import { ApiAppConfig, type SessionValues, isSessionValues } from '@ft/lib/models';
import { RoutingService } from '@ft/lib/routing-lib';
import { LocalStorageService } from '@furnas-technology/angular-library/services-storage';
import { CognitoJwtVerifier } from 'aws-jwt-verify';

const SESSION_VALUES = 'sessionValues';

@Injectable({
	providedIn: 'root',
})
export class AppInitService {
	imageService = inject(ImageStoreService);
	gss = inject(GlobalStore);
	env = inject(EnvironmentService);
	auth = inject(AuthService);
	authStore = inject(AuthStore);
	routing = inject(RoutingService);

	constructor() {}

	init() {
		console.debug(`👉 AppInitService - BEG`);
		return this.initializeAppSessionValues();
	}

	initializeAppSessionValues(): Observable<boolean> {
		console.debug(`👉 initializeAppSessionValues - BEG`);
		this.gss.setInitStatus('Initialising application');

		const x = this.routing.getNavigationRoutes();

		const gotSessionValues$ = this.loadSessionValuesFromApi().pipe(
			map((sessionValues: SessionValues | undefined) => {
				console.debug(`initializeAppSessionValues - sessionValues=`, sessionValues);

				if (!sessionValues) return false;

				// update logos and images
				this.gss.setInitStatus('Loading images');
				this.loadImages(sessionValues);

				// monitor ui-settings
				this.gss.setInitStatus('Setting up');
				// this.uiSettings.start();

				// create token verifiers
				this.gss.setInitStatus('Creating authentication verifier');
				const cognitoVerifier = this.createVerifiers(sessionValues.config);
				if (cognitoVerifier) {
					this.authStore.setCognitoVerifier(cognitoVerifier);
				}

				// is there an access token?
				console.debug(`initializeAppSessionValues - access_token=${!!this.authStore.selectAccessToken()}`);

				// return
				return true;
			}),
			switchMap((_) => {
				if (!this.authStore.selectAccessToken()) return of(false);

				this.gss.setInitStatus('Attempting to auto login');
				return this.auth.autoLogin(true).pipe(
					map((result) => {
						console.debug(`initializeAppSessionValues - autoLogin result=${result}`);
						return result;
					}),
				);
			}),
			catchError((err: unknown) => {
				console.error(`❌ initializeAppSessionValues - error:`, err);
				return of(false);
			}),
			finalize(() => {
				this.gss.setInitStatus('Completed');
				const initTime = this.gss.selectInitTime();
				console.debug(`👈 initializeAppSessionValues - COMPLETED - time=${initTime}`);
			}),
		);

		return gotSessionValues$;
	}

	/**
	 * Retrieves session values from API
	 */

	loadSessionValuesFromApi(): Observable<SessionValues | undefined> {
		try {
			console.debug(`🎲 loadSessionValuesFromApi - BEG`);
			const dataService = inject(DataService);
			const authStore = inject(AuthStore);

			const initAppUrl = CognitoUrls.initapp(this.env.authUrl(), this.env.loginCallback(), this.env.logoutCallback());
			console.debug(`loadSessionValuesFromApi - initAppUrl=${initAppUrl}`);

			return dataService.getPublic<SessionValues>(initAppUrl).pipe(
				map((sessionValues: SessionValues | undefined) => {
					if (sessionValues && isSessionValues(sessionValues)) {
						console.debug(
							`✅ loadSessionValuesFromApi - isSessionValues=${isSessionValues(sessionValues)}=`,
							sessionValues,
						);

						authStore.setSessionValues(sessionValues);
						return sessionValues;
					} else {
						console.error(`❌ loadSessionValuesFromApi - invalid session values=`, sessionValues);
						return undefined;
					}
				}),
				catchError((err: unknown) => {
					console.error(`❌ loadSessionValuesFromApi/dataservice error=`, err);
					return of(undefined);
				}),
			);
		} catch (err: unknown) {
			console.error(`❌ loadSessionValuesFromApi error=`, err);
			return of(undefined);
		}
	}

	/**
	 * create domain verifiers
	 */
	createVerifiers(apiAppConfig: ApiAppConfig): FT_CognitoVerifier | null {
		try {
			console.debug(`createVerifiers - BEG - apiAppConfig=`, apiAppConfig);
			const clientId = apiAppConfig.cognito.clientId;
			const poolId = apiAppConfig.cognito.poolId;
			if (!clientId || !poolId) return null;

			const verifierClientAndPool = clientId + poolId;
			const cognitoJwtVerifier = CognitoJwtVerifier.create({
				userPoolId: poolId,
				tokenUse: 'access',
				clientId: clientId,
			});

			const anyJwtVerifier = CognitoJwtVerifier.create({
				userPoolId: poolId,
				tokenUse: null,
				clientId: clientId,
			});

			return { verifierClientAndPool, cognitoJwtVerifier, anyJwtVerifier };
		} catch (err: unknown) {
			console.error(`❌ createVerifiers error=`, err);
			return null;
		}
	}

	loadImages(sessionValues: SessionValues): void {
		if (sessionValues.settings.logos) {
			this.imageService.loadAppLogos(sessionValues.config.imageUrl, sessionValues.settings.logos);
			console.debug(`initializeAppSessionValues - loadAppLogos completed`);
		}
	}
}

// values that are saved & restored from local storage
function loadSessionValuesFromStorage(): SessionValues | undefined {
	try {
		// load session values
		const value = inject(LocalStorageService).getLocalStorage(SESSION_VALUES);
		if (value) {
			const sessionValues: SessionValues = JSON.parse(value);
			if (isSessionValues(sessionValues)) {
				return sessionValues;
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	} catch (err: unknown) {
		console.error(`❌ loadSessionValuesFromStorage error=`, err);
		return undefined;
	}
}
