<div class="page-component" [ngClass]="layout.cssClasses()">
  <ft-close-button (closeClicked)="close()"></ft-close-button>

  <mat-card class="container terms-card">
    <mat-card-header class="header">
      <mat-card-title class="title">Terms of Service</mat-card-title>
      <mat-card-subtitle class="subtitle"> {{ appName }}</mat-card-subtitle>
      <ul class="toc">
        <li>
          <a href="{{ routing.baseRoute() }}#use">Use of the Website</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#content">User Content</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#ip">Intellectual Property</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#disclaimers">Disclaimers</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#liability">Limitation of Liability</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#termination">Termination</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#law">Governing Law</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#agreement">Entire Agreement</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#changes">Changes to the Terms</a>
        </li>
        <li>
          <a href="{{ routing.baseRoute() }}#contact">Contact</a>
        </li>
      </ul>
    </mat-card-header>
    <mat-card-content class="content">
      <p>
        These Terms of Service ("Terms") govern your access to and use of {{ host }} ("Website"), operated by
        {{ appName }} ("we," "us," or "our"). Please read these Terms carefully before using the Website. By accessing
        or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may
        not access or use the Website.
      </p>

      <!-- --- -->
      <h2 id="use">Use of the Website</h2>

      <p>• You may access and use the Website for your personal, non-commercial purposes only.</p>
      <p>
        • You may not download, copy, modify, distribute, transmit, display, sell, or create derivative works of any
        content on the Website without our prior written consent.
      </p>
      <p>
        • You are solely responsible for your use of the Website and for any content you post on the Website. You agree
        not to use the Website for any illegal or unauthorized purpose.
      </p>
      <p>
        • You agree not to interfere with or disrupt the Website or any servers or networks connected to the Website.
      </p>
      <!-- --- -->
      <h2 id="content">User Content</h2>
      <p>
        You may submit content (text, images, videos, etc.) to the Website ("User Content"). By submitting User Content,
        you grant us a non-exclusive, royalty-free, worldwide license to use, modify, publish, and translate your User
        Content in connection with the Website.
      </p>

      <p>
        • You represent and warrant that you own or have the necessary rights to submit User Content to the Website and
        that your User Content does not infringe on the rights of any third party.
      </p>

      <p>• You are solely responsible for your User Content and the consequences of submitting it to the Website.</p>
      <!-- --- -->
      <h2 id="ip">Intellectual Property</h2>
      <p>
        The Website and all content on the Website, including text, graphics, logos, images, and software, are protected
        by copyright, trademark, and other intellectual property laws. You may not use any content on the Website
        without our prior written consent.
      </p>
      <!-- --- -->
      <h2 id="disclaimers">Disclaimers</h2>
      <p>
        The information on the Website is provided for informational purposes only and is not intended as a substitute
        for professional advice.
      </p>

      <p>
        • We make no representations or warranties of any kind, express or implied, about the accuracy, completeness, or
        timeliness of the information on the Website.
      </p>

      <p>• We are not responsible for any errors or omissions in the information on the Website.</p>

      <!-- --- -->
      <h2 id="liability">Limitation of Liability</h2>
      <p>
        We will not be liable for any damages arising out of or related to your use of the Website, including but not
        limited to direct, indirect, incidental, consequential, or punitive damages.
      </p>
      <!-- --- -->
      <h2 id="termination">Termination</h2>
      <p>We may terminate your access to the Website for any reason, at any time, without notice.</p>
      <!-- --- -->
      <h2 id="law">Governing Law</h2>
      <p>These Terms will be governed by and construed in accordance with the laws of Australia.</p>
      <!-- --- -->
      <h2 id="agreement">Entire Agreement</h2>
      <p>These Terms constitute the entire agreement between you and us regarding your use of the Website.</p>
      <!-- --- -->
      <h2 id="changes">Changes to the Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the
        Website. Your continued use of the Website after the posting of the revised Terms means that you accept and
        agree to the changes.
      </p>
      <!-- --- -->
      <h2 id="contact">Contact for Privacy Policy Complaints and Enquiries</h2>
      <p>If you have any queries or complaints about our Privacy Policy please contact us at: {{ contact }}</p>
    </mat-card-content>
  </mat-card>
</div>
