export type FormlySelectOption = {
	label: string;
	value: string | number;
};

export const FORMLY_DATEPICKER_OPTIONS = {
	dateFormat: 'D',
	// startView: 'month',
	// datepickerTogglePosition: 'suffix',
	// disabled: false,
	// opened: false,
};

// datepickerOptions: {
//   dateFormat: 'yyyy-MM-dd',
//   startView: 'month',
//   datepickerTogglePosition: 'suffix',
//   disabled: false,
//   opened: false,
// },
