import { Injectable, computed, inject } from '@angular/core';
import { AuthService } from '@ft/lib/auth-lib';
import { RoutingService } from '@ft/lib/routing-lib';

@Injectable({
	providedIn: 'root',
})
export class ShowLoginService {
	authService = inject(AuthService);
	routing = inject(RoutingService);

	showLogin = computed(() => {
		const result = this.routing.isLoginError()
			? true
			: !(this.authService.authOrInprogress() || this.routing.isLoginOrLogoutUrl());
		const currentUrl = this.routing.getCurrentUrl();

		console.debug(`${this.constructor.name} - showLogin=${result}, currentUrl=${currentUrl}`);
		if (result) {
			this.routing.savePostLoginUrl(currentUrl);
		}

		return result;
	});

	showPleaseSubscribe = computed(() => {
		const currentUrl = this.routing.getCurrentUrl();
		const isErrorUrl = this.routing.isErrorUrl();
		const authOrInprogress = this.authService.authOrInprogress();
		const isLoginOrLogoutUrl = !!(this.routing.isLoginUrl() || this.routing.isLogoutUrl());
		const isAdminOrSubscribed = this.authService.isSubscribedOrAdmin();
		const hasUsername = !!this.authService.username();

		console.debug(
			`${this.constructor.name} - showPleaseSubscribe - currentUrl=${currentUrl}, isErrorUrl=${isErrorUrl}, routeInfo=`,
			this.routing.routeInfo(),
		);

		console.debug(
			`showPleaseSubscribe - currentUrl=${currentUrl}, authOrInprogress=${authOrInprogress}, isLoginOrLogoutUrl=${isLoginOrLogoutUrl}, isAdminOrSubscribed=${isAdminOrSubscribed}, hasUsername=${hasUsername}`,
		);

		if (!authOrInprogress) return false;
		if (isLoginOrLogoutUrl) return false;
		if (isAdminOrSubscribed) return false;
		if (!hasUsername) return false;

		return true;
	});
}
