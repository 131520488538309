import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type OnDestroy,
	type OnInit,
	inject,
	input,
	signal,
} from '@angular/core';
import { AuthService, AuthStore, type GetTokensResult } from '@ft/lib/auth-lib';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LastRouteService } from '@ft/lib/global-lib';
import { ImageStoreService } from '@ft/lib/media-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { BackgroundImageDirective } from '@furnas-technology/angular-library/directives';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { type Subscription, catchError, finalize, of } from 'rxjs';

const urlPatt = /.+?(?=\?)/;
const queryPatt = /^[^?#]+\?([^#]+)/;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	imports: [BackgroundImageDirective],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
	destroyRef = inject(DestroyRef);
	private routing = inject(RoutingService);
	private authStore = inject(AuthStore);

	private auth = inject(AuthService);
	private notifyService = inject(NotifyService);
	private lastRouteService = inject(LastRouteService);
	imageStore = inject(ImageStoreService);

	readonly code = input.required<string>();

	loading = signal<boolean>(true);

	isIframe = false;

	itemCount: number = 0;

	codeflowSubscription?: Subscription;

	public constructor() {}

	async ngOnInit(): Promise<void> {
		// determine if we are in an iFrame
		this.isIframe = window !== window.parent && !window.opener;

		// process queryParams
		const code = this.routing.routeQueryParams()?.['code'];
		const loginError = this.routing.routeQueryParams()?.['error'];
		const loginErrorDescription = this.routing.routeQueryParams()?.['error_description'];

		console.debug(`${this.constructor.name} - ngOnInit - code=${code || '*blank*'}`);
		if (code) {
			this.notifyService.info(`Verifying your account, please wait`, 'ℹ', 3000);
			this.codeFlow(code);
		} else if (!!loginError) {
			console.debug(`${this.constructor.name} - ngOnInit - loginError=${loginError}`);
			this.routing.updateUrl(this.routing.homeUrl);
			this.notifyService.error(
				`Error logging in: ${loginError}\r\n ${loginErrorDescription}`,
				undefined,
				undefined,
				'bottom',
				this.auth.performLogout,
			);
		} else if (this.authStore.isAuthenticated() === true) {
			console.debug(`${this.constructor.name} - already logged in - lastRoute=${this.lastRouteService.lastRoute()}`);
			this.notifyService.info(`You are already logged in`);
			// this.routing.goToSafeLastRoute();
			this.routing.redirectToPostLoginUrl();
		} else {
			console.debug(`${this.constructor.name} - no code and not authenticated`);
			const error = this.routing.routeError();
			const error_description = this.routing.routeErrorDesc();
			if (error) {
				this.notifyService.error(`Error logging in\r\n ${error}\r\n ${error_description}`);
			} else {
				this.notifyService.info(`Please login to continue`);
			}
			this.auth.performLogout();
		}
	}

	ngOnDestroy(): void {
		if (this.codeflowSubscription) {
			this.codeflowSubscription.unsubscribe();
		}
	}

	login() {
		try {
			const returnUrl = this.lastRouteService.lastRoute();
			this.notifyService.info(`Logging in...`);
			this.auth.performLogin(returnUrl);
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `logout`);
			this.notifyService.error(`Error logging in, please try again`);
			this.routing.routeToHome();
		}
	} // end login

	logout() {
		try {
			this.notifyService.info(`Logging out`, `Close`);
			this.auth.performLogout();
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `logout`);
			this.notifyService.error(`Error logging out`);
			this.routing.redirectTo('/');
		}
	}

	/**
	 * Exchange code for token
	 */
	codeFlow(code: string) {
		// remove code from url ... set to baseUrl
		this.routing.updateUrl(this.routing.baseRoute() ?? '');

		// get tokens using code
		this.codeflowSubscription = this.auth
			.exchangeCodeForTokens(code)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				catchError((err: unknown) => {
					const errMsg = FT_LogError(err, this.constructor.name, `auth codeFlow`);
					const errResult: GetTokensResult = {
						success: false,
						message: errMsg,
					};
					return of(errResult);
				}),
				finalize(() => console.debug(`${this.constructor.name} - auth codeFlow COMPLETED`)),
			)
			.subscribe((tokenResult: GetTokensResult) => {
				if (tokenResult?.success) {
					this.notifyService.success(`Successfully logged in`);
					// this.routing.goToSafeLastRoute();
					this.routing.redirectToPostLoginUrl();
				} else {
					console.debug(`${this.constructor.name} - auth codeFlow - tokenResult=`, tokenResult);
					this.routing.updateUrl(this.routing.homeUrl);
					this.notifyService.error(
						`Unable to login - please try again`,
						undefined,
						undefined,
						'bottom',
						this.auth.performLogout,
					);
					// this.auth.performLogout();
					// this.routing.routeToHome();
				}
			});
	} // end codeFlow
} // end class
