import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Inject,
	type OnInit,
	effect,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BUILD_DATE, LOGIN_URL, LOGOUT_URL } from '@ft/lib/core-lib';

import { faCheck, faUser } from '@fortawesome/free-solid-svg-icons';

import { AuthStore } from '@ft/lib/auth-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { ProfileInfoComponent } from '../profile-info/profile-info.component';

@Component({
	selector: 'ft-profile-button',
	templateUrl: './profile-button.component.html',
	styleUrls: ['./profile-button.component.scss'],
	imports: [
		FontAwesomeModule,
		MatButtonModule,
		MatMenuModule,
		MatSlideToggleModule,
		NgClass,
		ProfileInfoComponent,
		RouterModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileButtonComponent implements OnInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	authStore = inject(AuthStore);
	gss = inject(GlobalStore);

	router = inject(Router);

	menuItemsGroup = input<string[]>([]);

	menuTrigger = viewChild('profilemenuTrigger', { read: MatMenuTrigger });
	// menuTrigger = viewChild('menuTrigger', { read: MatMenuTrigger });

	profileMenu = viewChild('profilemenu', { read: MatMenu });
	// profileMenu = viewChild<MatMenu>('profilemenu');

	faUser = faUser;
	faCheck = faCheck;

	constructor(
		@Inject(LOGIN_URL) private loginUrl: string,
		@Inject(LOGOUT_URL) private logoutUrl: string,
		@Inject(BUILD_DATE) public buildDate: string,
	) {
		effect(() => {
			const rect = this.gss.selectComponentRect();
			console.debug(`${this.constructor.name} - effect - w/h=${rect?.width}/${rect?.height}`);
			this.closeMenu();
		});
	}

	async ngOnInit() {
		// nothing to see here
	}

	public logout() {
		console.debug(`${this.constructor.name} - logout - logoutUrl=${this.logoutUrl}`);
		this.router.navigate([this.logoutUrl]);
	}

	public login() {
		this.router.navigate([this.loginUrl]);
	}

	public logoClick() {
		this.router.navigateByUrl('/');
	}

	changeDarkMode(darkMode: boolean) {
		this.layout.setDarkMode(darkMode);
	}

	closeMenu() {
		console.debug(`${this.constructor.name} - closeMenu `);
		// if (this.menuTrigger?.menuOpen) {
		this.menuTrigger()?.closeMenu();
		// }
	}
}
