import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type OnInit,
	computed,
	effect,
	inject,
	input,
	signal,
	viewChild,
	viewChildren,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatListModule } from '@angular/material/list';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthStore } from '@ft/lib/auth-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { MenuItem, MenuService } from '../menu.service';
import { FT_VerticalMenuComponent } from '../vertical-menu/vertical-menu.component';

@Component({
	selector: 'ft-horizontal-menu',
	templateUrl: './horizontal-menu.component.html',
	styleUrls: ['./horizontal-menu.component.scss'],
	imports: [
		NgClass,
		FontAwesomeModule,
		FT_VerticalMenuComponent,
		MatMenuModule,
		MatTooltipModule,
		RouterModule,
		MatListModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_HorizontalMenuComponent implements OnInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	protected authStore = inject(AuthStore);
	protected gss = inject(GlobalStore);

	private menuService = inject(MenuService);
	protected routing = inject(RoutingService);

	navbarMenu = viewChild<MatMenu>(MatMenu);

	menuTriggers = viewChildren('menuTrigger', { read: MatMenuTrigger });
	// menuTriggers = viewChildren(MatMenuTrigger);

	childMenu = viewChild('childMenu', { read: MatMenu });
	// childMenu = viewChild<MatMenu>(MatMenu);

	caller = input<string>('');
	startingMenuItems = input<MenuItem[]>();

	menuItems = computed<MenuItem[]>(() => {
		console.debug(`${this.constructor.name} - menuItems - startingMenuItems=`, this.startingMenuItems());
		const items = !!this.startingMenuItems() ? this.startingMenuItems() || [] : this.menuService.getMenuItems();
		console.debug(`${this.constructor.name} - menuItems - items=`, items);
		return items;
	});

	selectedChildren = signal<MenuItem[]>([]);
	selectedParentTitle = signal<string>('');

	showTooltip = computed(() => this.layout.isMedium() && !this.layout.isTouch() && !this.aMenuIsOpen());

	aMenuIsOpen = signal<boolean>(false);

	itemTextClass = computed(() => {
		return this.layout.isMedium() ? 'hide-menu-text' : 'show-menu-text';
	});

	topRoutePath = computed(() => {
		console.debug(`${this.constructor.name} - topRoutePath - this.routing.routeInfo()=`, this.routing.routeInfo());
		const fullPath = this.routing.routeInfo()?.path ?? [];
		return fullPath.length ? fullPath[0] : '';
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		effect(() => {
			console.debug(`${this.constructor.name} - effect - this.menuTriggers=`, this.menuTriggers());
			const isOpen = this.menuTriggers().some((trigger) => trigger.menuOpen);
			this.aMenuIsOpen.set(isOpen);
		});

		effect(() => {
			console.debug(`${this.constructor.name} - effect - this.layout.resized=`, this.layout.resized());
			const isOpen = this.menuTriggers().some((trigger) => trigger.menuOpen);
			if (isOpen) {
				for (const trigger of this.menuTriggers()) {
					if (trigger.menuOpen) {
						trigger.closeMenu();
					}
				}
			}
		});
	}

	ngOnInit() {}

	onPageNavItemsEnter(_evt: MouseEvent) {}

	toggleSubMenu(evt: Event, menuItem: MenuItem) {
		console.debug(
			`${this.constructor.name} - toggleSubMenu - menu=${menuItem.menuTitle}, routeItem=${menuItem.routeItem} , menuItem=`,
			menuItem,
		);
	}

	setChildrenMenu(children: MenuItem[], trigger: MatMenuTrigger, menuItem: MenuItem) {
		console.debug(`${this.constructor.name} - setChildrenMenu - currentRoute=${this.routing.routeInfo()?.path || ''} `);

		this.selectedParentTitle.set(menuItem.menuTitle);
		this.selectedChildren.set(children);
		trigger.openMenu();
	}

	onMenuClosed(trigger: MatMenuTrigger) {
		this.selectedChildren.set([]);
		this.selectedParentTitle.set('');
	}

	onMenuItemClick(menuItem: MenuItem) {
		console.debug(`${this.constructor.name} - onMenuItemClick - menuItem=`, menuItem);
		for (const trigger of this.menuTriggers()) {
			if (trigger.menuOpen) {
				trigger.closeMenu();
			}
		}
	}
}
