import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AuthService, AuthStore } from '@ft/lib/auth-lib';
import { ProgressSpinnerComponent } from '@furnas-technology/angular-library/components';

@Component({
	selector: 'ft-login-button',
	templateUrl: './login-button.component.html',
	styleUrls: ['./login-button.component.scss'],
	imports: [FontAwesomeModule, MatButtonModule, MatTooltipModule, ProgressSpinnerComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginButtonComponent {
	auth = inject(AuthService);
	authStore = inject(AuthStore);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	public login() {
		console.debug(`${this.constructor.name} - calling performLogin`);
		this.auth.performLogin();
	}
}
