import { ChangeDetectionStrategy, Component, DestroyRef, Inject, type OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ft/lib/auth-lib';

import { HOME_URL } from '@ft/lib/core-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';

@Component({
	standalone: true,
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
	destroyRef = inject(DestroyRef);
	private routing = inject(RoutingService);

	router = inject(Router);
	auth = inject(AuthService);
	notifyService = inject(NotifyService);

	public constructor(@Inject(HOME_URL) private homeUrl: string) {}

	async ngOnInit(): Promise<void> {
		this.auth.performLogout();
		this.routing.routeToHome();
	}
} // end class
