<div class="profile-button-component" [ngClass]="layout.cssClasses()">
  <button
    mat-button
    [matMenuTriggerFor]="profilemenu"
    [ngClass]="{ 'rotate-y': authStore.authInProgress() }"
    aria-label="Profile"
    #profilemenuTrigger
  >
    <fa-icon class="profile-icon" [icon]="faUser"></fa-icon>
    @if (authStore.isAuthenticated()) {
      <div class="authenticated clickable">
        <fa-icon [icon]="faCheck"></fa-icon>
      </div>
    }
  </button>

  <mat-menu #profilemenu="matMenu" panelClass="profile-button-menu" class="profile-button-menu">
    <div class="user-info-menu">
      <div class="user-info-menu__info" (click)="$event.stopPropagation()">
        <ft-profile-info></ft-profile-info>
      </div>
      <div class="settings-menu">
        <mat-slide-toggle
          class="theme-toggle"
          [checked]="layout.uiMode() === 'darkMode'"
          (change)="changeDarkMode($event.checked)"
          >Dark Mode</mat-slide-toggle
        >
      </div>
      <div class="user-info-menu__login">
        @if (authStore.isAuthenticated() === true) {
          <div mat-menu-item class="logout-text clickable" (click)="logout()">Logout</div>
        } @else {
          <div mat-menu-item class="login-text clickable" (click)="login()">Login</div>
        }
      </div>
      <div class="footer">
        <div class="clickable" routerLink="privacy">Privacy Policy</div>
        <div class="clickable" routerLink="terms">Terms of Use</div>
        <div>{{ layout.deviceDesc() }}</div>
        <div>{{ buildDate }}</div>
      </div>
    </div>
  </mat-menu>
</div>
