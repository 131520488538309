import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FT_ErrorHandler implements ErrorHandler {
	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}
	handleError(error: unknown): void {
		// Alert the developer (you can replace this with a more sophisticated notification system)
		console.error('An uncaught error occurred. Check the console for details. Error:', error);
		alert(`An uncaught error occurred. Check the console for details. Error: ${JSON.stringify(error)}`);
		// do something with the exception
	}
}
