import { ChangeDetectionStrategy, Component, DestroyRef, computed, inject, input, signal } from '@angular/core';

import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from '@ft/lib/auth-lib';
import { ShowLoginService } from '@ft/lib/auth-user-lib';
import { BackgroundService } from '@ft/lib/background-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { WelcomeComponent } from '@ft/lib/login-and-welcome';
import { NavbarComponent, SidebarComponent } from '@ft/lib/navigation-and-menu';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { BackgroundImageDirective, ResizeObserverDirective } from '@furnas-technology/angular-library/directives';
import { BackgroundParams, getBackgroundParams } from '@furnas-technology/common-library/functions';

@Component({
	selector: 'ft-app-base-layout',
	templateUrl: './app-base-layout.component.html',
	styleUrls: ['./app-base-layout.component.scss'],
	imports: [
		BackgroundImageDirective,
		NgClass,
		NavbarComponent,
		SidebarComponent,
		RouterOutlet,
		WelcomeComponent,
		ResizeObserverDirective,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBaseLayoutComponent {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private backgroundService = inject(BackgroundService);
	private authStore = inject(AuthStore);
	private showLoginService = inject(ShowLoginService);
	protected gss = inject(GlobalStore);

	sidbarIsHidden = this.layout.sidbarIsHidden;

	showlogin = this.showLoginService.showLogin;

	baseBackground = input<string>(this.backgroundService.backgroundImage());

	loggedInOutClass = this.authStore.loggedInOutClass;
	isAuthenticated = this.authStore.isAuthenticated;
	authInProgress = this.authStore.authInProgress;

	screenWidth = signal<number>(0);
	screenHeight = signal<number>(0);

	backgroundParams = computed<BackgroundParams>(() => {
		return getBackgroundParams(this.screenWidth(), this.screenHeight());
	});

	onResize(event: DOMRectReadOnly) {
		this.screenWidth.set(event.width);
		this.screenHeight.set(event.height);
	}
}
