import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { FieldType, type FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { type ContentChange, QuillModule, QuillModules } from 'ngx-quill';
import Quill from 'quill';
import { QuillEditService } from './quill-edit.service';

@Component({
	selector: 'ft-quill-type',
	templateUrl: './quill-type.component.html',
	styleUrls: ['./quill-type.component.scss'],
	imports: [QuillModule, FormsModule, FormlyModule, MatFormFieldModule, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuillTypeComponent extends FieldType<FieldTypeConfig> {
	destroyRef = inject(DestroyRef);
	quillEditService = inject(QuillEditService);

	editorContent = '';
	editor: Quill | undefined;

	quillModules: QuillModules = {
		toolbar: {
			container: [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{ font: this.quillEditService.customFonts }],
				[
					{ list: 'ordered', title: 'Ordered List' },
					{ list: 'bullet', title: 'Bullet List' },
				],
				[{ align: [] }],
				[{ float: ['left', 'right', 'center', 'justify', 'none'] }],

				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

				['blockquote', 'code-block'],

				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
				[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
				[{ direction: 'rtl' }], // text direction

				[{ color: [] }, { background: [] }], // dropdown with defaults from theme

				['link', 'image', 'video'],

				['clean'], // remove formatting button
			],
		},
		// font: {
		//   whitelist: ['', 'monospace', 'futura', 'cursive', 'monaco', 'helvetica', 'arial'],
		// },
	};
	htmlText = '';

	constructor() {
		super();

		this.quillEditService.initQuillEditor();
	}

	ngOnInit() {
		const key = (this.key as string) ?? '';
		const value = key ? (this.model[key] ?? '') : '';
		this.htmlText = value;
	}

	contentChanged(evt: ContentChange) {
		try {
			console.debug(`${this.constructor.name} - contentChanged - evt=`, evt);

			const key = this.key as string;
			this.model[key] = evt.html;

			// update the form
			const formControl = this.form.get(key as string);
			if (formControl) {
				formControl.markAsDirty();
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `contentChanged`);
		}
	}

	created(quill: Quill) {
		this.editor = quill;
		console.debug(`${this.constructor.name} - created - quill=`, quill);
	}
}
