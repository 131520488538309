<div class="options-component">
  <ng-select
    #syllabusselect
    name="syllabusoptions"
    class="select-options"
    [items]="sortedSyllabuses()"
    [multiple]="true"
    autofocus
    bindLabel="syllabusName"
    bindValue="_id"
    [ngModel]="selectedIds()"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    (change)="onChange($event)"
    listItemWidth
  >
    <ng-template ng-option-tmp let-item="item" let-index="index" class="ft-select-option">
      <div class="option-card options-component-option" #seloptions>
        <div class="item-name">{{ item.syllabusName }}</div>
        <div class="item-addl-info">&nbsp;({{ competitionService.selectSyllabusCount(item._id) }})</div>
      </div>
    </ng-template>
  </ng-select>

  <ft-add-button addText="Add new syllabus" (addClicked)="onCreate()"></ft-add-button>
</div>
