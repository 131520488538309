<div
  class="sidebar"
  [ngClass]="[
    layout.sidbarIsHidden() ? 'sidebar--minimised' : 'sidebar--maximised',
    gss.selectNavbarSettings()?.hasSidebar ? 'sidebar--show' : 'sidebar--hide',
  ]"
  [style.top]="gss.navbarHeight()"
>
  @if (routing.title()) {
    <div class="page-heading"></div>
  }

  <div class="sidebar__menu hide-scrollbars">
    <div class="sidebar__menu-content"></div>
  </div>
  <div class="sidebar__resize-chevron-container" mat-button (click)="hideSidebarToggle()">
    @if (!layout.sidbarIsHidden()) {
      <fa-icon [icon]="faCircleChevronLeft"></fa-icon>
    }
    @if (layout.sidbarIsHidden()) {
      <fa-icon [icon]="faCircleChevronRight"></fa-icon>
    }
  </div>
</div>
