import '@angular/compiler';
import { bootstrapApplication } from '@angular/platform-browser';

import { ApplicationRef, enableProdMode } from '@angular/core';
import { FunctionOverrides } from '@furnas-technology/angular-library/functions';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

FunctionOverrides(environment.production);

async function bootstrap() {
	const applicationRef = await bootstrapApplication(AppComponent, appConfig);
	return applicationRef;
}

bootstrap()
	.then((applicationRef: ApplicationRef) => {
		// You can now pass applicationRef to your library function
		console.debug(`😁 Application bootstrapped`);
		// myLibraryFunction(applicationRef);
	})
	.catch((err) => console.error(err))
	.finally(() => console.debug(`🏁 bootstrap completed`));
