import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Inject,
	type OnInit,
	computed,
	effect,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { NgClass, NgStyle } from '@angular/common';

import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatButtonModule } from '@angular/material/button';
import { faBars, faSliders } from '@fortawesome/free-solid-svg-icons';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { performLogin, performLogout } from '@ft/lib/auth-user-lib';
import { DialogButtonComponent } from '@ft/lib/button-lib';
import { ContactService } from '@ft/lib/contact-lib';
import { HAS_NOTIFICATIONS, HOME_URL, IS_PRODUCTION, STRIPE_PK } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { FilterButtonComponent } from '@ft/lib/filter-lib';
import { SearchBarComponent } from '@ft/lib/filter-searchbar';
import { GlobalStore } from '@ft/lib/global-lib';
import { ProfileButtonComponent } from '@ft/lib/profile-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { LayoutInfoService, WindowOrientation } from '@ft/lib/screen-lib';
import { SubscriptionService } from '@ft/lib/stripe-lib';
import { UserNotificationService } from '@ft/lib/user-notifications-lib';
import { DisplayLogoComponent } from '@furnas-technology/angular-library/components';
import { EscapeKeyListenerDirective } from '@furnas-technology/angular-library/directives';
import type { EmptyObject } from '@furnas-technology/common-library/functions';
import { FT_HorizontalMenuComponent } from '../horizontal-menu/horizontal-menu.component';
import { MenuItem, MenuService } from '../menu.service';
import { FT_VerticalMenuComponent } from '../vertical-menu/vertical-menu.component';

type NgStyleType = {
	[key: string]: string | number | undefined;
};

@Component({
	selector: 'ft-app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	imports: [
		NgClass,
		NgStyle,
		DialogButtonComponent,
		DisplayLogoComponent,
		EscapeKeyListenerDirective,
		FilterButtonComponent,
		FontAwesomeModule,
		FT_HorizontalMenuComponent,
		FT_VerticalMenuComponent,
		MatButtonModule,
		MatMenuModule,
		MatTooltipModule,
		ProfileButtonComponent,
		RouterLink,
		SearchBarComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private authStore = inject(AuthStore);
	private gss = inject(GlobalStore);
	private afs = inject(ActiveFilterService);
	private environment = inject(EnvironmentService);
	protected routing = inject(RoutingService);
	private menuService = inject(MenuService);

	protected contactService = inject(ContactService);
	protected notificationService = inject(UserNotificationService);
	protected subscriptionService = inject(SubscriptionService);

	isMinimised = input<boolean>(false);
	dialogConfig = input<unknown>(null);

	logoPortraitUrl = input<string>(this.environment.logoPortrait);
	logoLandscapeUrl = input<string>(this.environment.logoLandscape);
	logoSquareUrl = input<string>(this.environment.logoSquare);
	faviconUrl = input<string>(this.environment.icon);

	navbarMenu = viewChild('navbarMenu', { read: MatMenu });
	// navbarMenu = viewChild<MatMenu>(MatMenu);
	menuTrigger = viewChild('menuTrigger', { read: MatMenuTrigger });
	// menuTrigger = viewChild<MatMenuTrigger>(MatMenuTrigger);

	faSliders = faSliders;
	faBars = faBars;

	isAuthenticated = this.authStore.isAuthenticated;
	isSubscribed = this.authStore.isSubscribed;
	isAdmin = this.authStore.isAdmin;

	menuItems = computed<MenuItem[]>(() => {
		return this.menuService.getMenuItems();
	});

	navbarClasses = computed<string>(() => {
		const navbarTypeClass: string = this.navbarSettings()?.navbarType || 'default';
		const classString = `${this.layout.cssClasses().trim()} ${navbarTypeClass}`.trim();
		return classString;
	});

	displayOverride = computed<NgStyleType>(() => {
		return this.authStore.isAuthenticated() ? { display: 'flex' } : { display: 'none' };
	});

	paddingPixels = computed<number>(() => {
		return this.authStore.isAuthenticated() ? 3 : 0;
	});

	navbarHeight = this.gss.navbarHeight;
	navbarHeightPixels = computed<number>(() => {
		return Number.parseInt(this.gss.navbarHeight(), 10);
	});
	logoHeightPixels = computed<number>(() => {
		return this.navbarHeightPixels();
	});

	logoWidthPixels = computed<number>(() => {
		const landscapeWidth = Math.round(this.logoHeightPixels() * 1.78);

		if (this.layout.windowOrientation() === 'landscape') {
			return landscapeWidth;
		} else {
			return Math.min(this.logoHeightPixels(), landscapeWidth);
		}
		// if (this.layout.isSquareish()) {
		// 	return this.logoHeightPixels();
		// } else if (this.layout.windowOrientation() === 'landscape') {
		// 	return Math.round(this.logoHeightPixels() * 1.78);
		// } else {
		// 	return this.logoHeightPixels();
		// 	// return Math.round(this.logoHeightPixels() * 0.67);
		// }
	});

	navbarStyles = computed<NgStyleType>(() => {
		if (this.authStore.isAuthenticated()) {
			const styles = {
				display: `${this.displayOverride()}`,
				height: `${this.navbarHeight()}`,
			};
			return styles;
		}
		return {};
	});

	navbarNonLogoStyles = computed<NgStyleType>(() => {
		const styles = {
			padding: `${this.paddingPixels()}px`,
		};
		return styles;
	});

	logoOrientation = computed<WindowOrientation>(() => {
		return this.layout.ltMediumScreen() || this.layout.isPortrait() ? 'portrait' : 'landscape';
	});

	logoUrl = computed<string>(() => {
		if (this.layout.isSquareish()) {
			return this.logoSquareUrl() || this.logoLandscapeUrl() || this.logoPortraitUrl();
		} else if (this.layout.isLandscape()) {
			return this.logoLandscapeUrl() || this.logoSquareUrl() || this.logoPortraitUrl();
		} else {
			return this.logoPortraitUrl() || this.logoSquareUrl() || this.logoLandscapeUrl();
		}
	});

	logoSrc = computed<string>(() => {
		return `${this.logoUrl()}?width=${this.logoWidthPixels()}&height=${this.logoHeightPixels()}&fit=cover`;
	});

	logoClasses = computed<string>(() => {
		const classes: string[] = [];
		if (this.layout.sidbarIsHidden()) classes.push('sidebar-minimised');
		classes.push(this.layout.windowOrientation());
		return classes.join(' ');
	});

	filter: EmptyObject = {};

	navbarSettings = this.gss.selectNavbarSettings;

	selectShowSearchBar = this.afs.selectShowSearchBar;
	selectActiveComponentFilter = this.afs.selectActiveComponentFilter;

	constructor(
		@Inject(HAS_NOTIFICATIONS) public hasNotifications: boolean,
		@Inject(HOME_URL) public homeUrl: string,
		@Inject(IS_PRODUCTION) protected isProduction: boolean,
		@Inject(STRIPE_PK) protected stripePublishableKey: string,
	) {
		console.debug(`${this.constructor.name} - constructor`);

		// close menu if open
		effect(() => {
			if (this.layout.screenWidthHeight()) {
				this.closeMenu();
			}
		});
	}

	protected closeMenu() {
		if (this.menuTrigger()?.menuOpen) {
			this.menuTrigger()?.closeMenu();
		}
	}

	ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit - layout.classes=`, this.layout.cssClasses());
	}

	ngAfterViewInit() {}

	public logout() {
		performLogout();
	}

	public login() {
		performLogin();
	}

	logoClick(): void {
		this.routing.routeToHome();
	}

	onMenuItemClick(menuItem: MenuItem) {
		console.debug(`${this.constructor.name} - onMenuItemClick - menuItem=`, menuItem);
		if (this.menuTrigger()?.menuOpen) {
			this.menuTrigger()?.closeMenu();
		}
	}
	// toggleCollapsedMenu() {
	// console.debug(`${this.constructor.name} - toggleCollapsedMenu - navbarMenu=`, this.navbarMenu());
	// console.debug(
	// 	`${this.constructor.name} - toggleCollapsedMenu -  open=${this.menuTrigger()?.menuOpen}, menuTrigger=`,
	// 	this.menuTrigger(),
	// );
	// this.menuTrigger()?.toggleMenu();
	// }
}
