<!-- <pre>{{ field | json }}</pre> -->
@if (field.props['searchBar']) {
<div class="search-bar">
  <ft-search-bar
    [globalSearch]="false"
    (escapeKey)="handleEscapeKeyPress()"
    (searchTerm)="handleSearch($event)"
  ></ft-search-bar>
</div>
}
<div class="ft-repeat-group">
  <div class="ft-repeat-heading">
    @if (props.label) {
    <legend>{{ props.label }}</legend>
    } @if (props.description) {
    <p>{{ props.description }}</p>
    } @if (props['paste']) {
    <fa-icon
      matTooltip="Paste text rows"
      class="clickable color-primary"
      (click)="handlePasteRows()"
      [icon]="faFileLines"
    ></fa-icon>
    }
  </div>
  <div class="ft-repeat-content">
    <!-- <pre>{{ field.fieldGroup | json }}</pre> -->
    @for (fieldOfGroup of field.fieldGroup; track fieldOfGroup; let i = $index) { @if(!fieldOfGroup.hide) {
    <div class="ft-row ft-align-items-baseline" [attr.data-hidden-row]="fieldOfGroup.hide ? '' : null">
      <!-- fieldOfGroup i={{ i }} field = -->
      <!-- <pre>{{ fieldOfGroup | json }}</pre> -->
      <!-- <pre>{{ fieldOfGroup.model | json }}</pre> -->
      <formly-field
        class="ft-repeat-field"
        [field]="fieldOfGroup"
        [attr.data-invalid]="fieldOfGroup?.formControl?.invalid"
        [attr.data-valid]="fieldOfGroup?.formControl?.valid"
        [attr.aria-label]="fieldOfGroup.id"
        autocomplete="off"
        id="{{ fieldOfGroup.id }}"
      ></formly-field>
      <div class="ft-repeat-row-buttons">
        @if (!fieldOfGroup.hide && !fieldOfGroup.props?.readonly) {
        <div class="ft-remove-button clickable" matTooltip="Remove">
          <div class="button color-accent" type="button" (click)="remove(i)">
            <fa-icon [icon]="faSquareMinus"></fa-icon>
          </div>
        </div>
        } @if (!fieldOfGroup.hide && !fieldOfGroup.props?.readonly && fieldOfGroup.props?.['clone']) {
        <div class="ft-clone-button clickable" matTooltip="Duplicate">
          <div class="button color-accent" type="button" (click)="handleClone(i, fieldOfGroup)">
            <fa-icon [icon]="faClone"></fa-icon>
          </div>
        </div>
        }
      </div>
    </div>
    } }
  </div>
  @if (!props.readonly) {
  <div class="ft-add-button clickable">
    <button class="btn btn-primary accent clickable" type="button" (click)="customAdd()" aria-label="Add text">
      {{ props['addText'] }}
    </button>
  </div>
  }
</div>
