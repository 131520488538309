import { NgClass } from '@angular/common';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Inject,
	type OnChanges,
	type OnInit,
	inject,
	input,
	signal,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { APP_NAME } from '@ft/lib/core-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.scss'],
	imports: [CloseIconButtonComponent, NgClass, MatCardModule, RouterModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyComponent implements OnInit, OnChanges, AfterViewInit {
	protected layout = inject(LayoutInfoService);
	protected routing = inject(RoutingService);

	host = window.location.host;

	contact = `info@furnas.technology`;

	readonly company = input<string | undefined>('');
	loading = signal<boolean>(true);
	public constructor(@Inject(APP_NAME) protected appName: string) {}

	async ngOnInit(): Promise<void> {}

	ngAfterViewInit(): void {}

	ngOnChanges(changes: unknown) {}

	close(): void {
		this.routing.navigate(['/']);
	}
} // end class
