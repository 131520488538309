import { NgClass } from '@angular/common';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	Inject,
	type OnInit,
	Renderer2,
	computed,
	inject,
	isDevMode,
	signal,
} from '@angular/core';

import { Title } from '@angular/platform-browser';

import packageJson from '../../../../package.json';

import { AuthService } from '@ft/lib/auth-lib';
import { ShowLoginService } from '@ft/lib/auth-user-lib';
import { BackgroundStore } from '@ft/lib/background-lib';
import { PleaseSubscribeComponent } from '@ft/lib/components';
import { APP_NAME } from '@ft/lib/core-lib';
import { CommonDialogService } from '@ft/lib/dialogs-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { AppBaseLayoutComponent } from '@ft/lib/layout-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { ResizeObserverDirective } from '@furnas-technology/angular-library/directives';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	imports: [AppBaseLayoutComponent, NgClass, PleaseSubscribeComponent, ResizeObserverDirective],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	private layout = inject(LayoutInfoService);
	private backgroundStore = inject(BackgroundStore);
	private authService = inject(AuthService);
	private gss = inject(GlobalStore);
	private renderer = inject(Renderer2);

	private titleService = inject(Title);
	private dialogService = inject(CommonDialogService);
	private routing = inject(RoutingService);
	private showLoginService = inject(ShowLoginService);

	isAuthenticated = this.authService.isAuthenticated;
	selectInitStatus = this.gss.selectInitStatus;
	backgroundImage = this.backgroundStore.backgroundImage;

	minAutoLogoutMinutes = 1;
	defaultAutoLogoutMinutes = 15;
	autoLogoutMinutes: number = this.defaultAutoLogoutMinutes;
	autoLogoutTimeout: number = 0;

	currentTheme = 'default';
	@HostBinding('class') classes = '';

	public packageVersion: string = packageJson.version;
	public packageName: string = packageJson.name;

	title: string = '';

	isIframe = false;
	loginDisplay = false;

	closedPleaseSubscribe = signal<boolean>(false);
	showPleaseSubscribe = this.showLoginService.showPleaseSubscribe;

	appClasses = computed(() => {
		return `${this.currentTheme} ${this.layout.uiModeClass()} ${this.layout.cssClasses()}`.trim();
	});

	public constructor(@Inject(APP_NAME) protected appName: string) {
		console.debug(`${this.constructor.name} - constructor - isDevMode=${isDevMode()}`);

		this.titleService.setTitle(this.appName);
		this.title = this.titleService.getTitle();

		// set theme classes
		this.layout.setCurrentTheme(this.currentTheme);
		this.classes = this.layout.themeClasses;
	}

	ngOnInit() {
		// set if operating in an Iframe
		this.isIframe = window !== window.parent && !window.opener;
	}

	ngAfterViewInit() {
		this.renderer.removeClass(document.body.firstElementChild, 'shimmer-background');
		this.layout.monitorBreakpoints();
	} // end ngAfterViewInit

	onResize(evt: DOMRectReadOnly) {
		console.debug(`${this.constructor.name} - onResize - evt=`, evt);
		this.gss.setComponentRect(evt);
	}
}
