import { BuildEnvironment } from './environment.base';

const env = BuildEnvironment({
	production: true,
	stage: 'prod',
	baseApiUrl: `https://ht7y5x17y9.execute-api.us-east-1.amazonaws.com/`,
	appName: `DanceComps.com.au`,

	stripePT: 'prctbl_1PaUTlBd4pDUi81IIvUqJEG0',
	stripePK:
		'pk_live_51NZnODBd4pDUi81IROtgIgue3UtAFVLxbLa2H5ZLI43kLGMdt7z2LIt9uphnOGoMrqezbl6SGCmUGBmSrr4Qge2S00OqmoQQJZ',
	stripePortal: 'https://billing.stripe.com/p/login/14kdTq61HgYfeL64gg',
	googleConversionTag: '',
});
export const environment = env;
