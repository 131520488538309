import { Inject, Injectable, inject, signal } from '@angular/core';

import type { ActivatedRouteSnapshot, ResolveFn, Route, RouterStateSnapshot } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthStore } from '@ft/lib/auth-lib';
import { ADMIN_GROUPS, IGNORED_URLS, ROUTES, USER_GROUPS } from '@ft/lib/core-lib';

export type MenuItem = {
	menuTitle: string;
	menuIcon: string;
	menuPath: string[];
	routeItem: boolean;
	children: MenuItem[];
};

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	readonly authStore = inject(AuthStore);

	readonly routes = signal<Route[]>([]);
	readonly adminGroups = signal<string[]>([]);
	readonly userGroups = signal<string[]>([]);

	constructor(
		@Inject(IGNORED_URLS) private ignoredUrls: string[] = [],
		@Inject(ADMIN_GROUPS) private DFT_adminGroups: string[] = [],
		@Inject(USER_GROUPS) private DFT_userGroups: string[] = [],
		@Inject(ROUTES) private DFT_routes: Route[] = [],
	) {
		this.adminGroups.set(this.DFT_adminGroups);
		this.userGroups.set(this.DFT_userGroups);
		this.routes.set(this.DFT_routes);
	}

	// filter routes to show in navbar
	navbarRoutes(userGroups: string[], isAuthenticated: boolean): Route[] {
		const routes: Route[] = [];

		for (const route of this.routes()) {
			// skip if no title
			if (!route?.title) continue;
			// skip if no route data - not a navbar item
			if (!route?.data) continue;
			if (!route?.data['showInNavbar']) continue;
			if (typeof route?.data !== 'object' || Object.keys(route?.data).length === 0) continue;

			// canActivate guard means user must be logged in
			if (route.canActivate?.length && !isAuthenticated) continue;

			// authentication filter
			if (route?.data['authenticatedStatus'] === 'authenticated' && !isAuthenticated) continue;
			if (route?.data['authenticatedStatus'] === 'unauthenticated' && isAuthenticated) continue;

			// if route requires allowedGroups and no user groups, skip
			if (route?.data['allowedGroups'] && route?.data['allowedGroups']?.length > 0 && userGroups.length === 0) continue;

			// if no allowedGroups specified or empty, then it is available to all users
			if (!route?.data['allowedGroups'] || route?.data['allowedGroups']?.length === 0) {
				routes.push(route);
			} else {
				// check if userGroups contains allowedGroups
				const matchingGroups = route?.data['allowedGroups'].filter(
					(group: string) => userGroups.findIndex((item) => item.toLowerCase() === group.toLowerCase()) > -1,
				);

				if (matchingGroups.length > 0) {
					routes.push(route);
				}
			}
		}

		console.debug(
			`${this.constructor.name} - navbarRoutes=\n${routes
				.map((route) => `route=${route.title} - path=${route.path}`)
				.join(', ')}`,
		);

		/**
		 * return routes
		 */
		return routes;
	}

	getMenuTitle(route: Route) {
		const title = route.title;
		if (typeof title === 'string') {
			return title;
		} else if (typeof title === 'function') {
			// Assume it's a ResolveFn
			return (title as ResolveFn<string>)({} as ActivatedRouteSnapshot, {} as RouterStateSnapshot).toString();
		} else if (title && typeof title === 'object') {
			// Assume it's a Type<Resolve<string>>
			return new (title as new () => string)().toString();
		}
		return '';
	}

	getMenuItems(startingRoutes: Route[] | null = null, parentPath: string[] = [], level = 0): MenuItem[] {
		const routes: Route[] = !!startingRoutes
			? startingRoutes
			: this.navbarRoutes(this.authStore.groups(), this.authStore.isAuthenticated() ?? false);
		const menuItems: MenuItem[] = [];
		if (routes.length === 0) return menuItems;

		for (const route of routes) {
			const routePath = !!route.path ? [route.path] : [];
			const path = [...parentPath, ...routePath];
			const childRoutes = (route.data?.['children'] ?? []) as Route[];
			const childMenus = this.getMenuItems(childRoutes, path, level + 1);

			const showInNavbar = route.data?.['showInNavbar'] ?? true;

			if (!!route.title && showInNavbar) {
				menuItems.push({
					menuTitle: this.getMenuTitle(route),
					menuIcon: route.data?.['icon'] ?? faBars,
					routeItem: !route.data?.['noRoute'],
					menuPath: path,
					children: childMenus,
				});
			}
		}

		console.debug(`${this.constructor.name} - level=${level} getMenuItems - menuItems=`, menuItems);
		return menuItems;
	}
} // end class
