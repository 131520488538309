import { ChangeDetectionStrategy, Component, type OnInit, inject, output } from '@angular/core';

import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
	faChevronLeft,
	faChevronRight,
	faCircleChevronLeft,
	faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { GlobalStore } from '@ft/lib/global-lib';
import { RoutingService } from '@ft/lib/routing-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';

@Component({
	selector: 'ft-app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	imports: [NgClass, FontAwesomeModule, MatButtonModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
	protected layout = inject(LayoutInfoService);
	routing = inject(RoutingService);
	gss = inject(GlobalStore);

	menuToggle = output();

	faChevronLeft = faChevronLeft;
	faChevronRight = faChevronRight;
	faCircleChevronLeft = faCircleChevronLeft;
	faCircleChevronRight = faCircleChevronRight;

	pageTitle = '';
	textAnimate = '';

	constructor() {
		// console.debug(`SidebarComponent - constructor`);
	}

	ngOnInit() {
		// nothing to see here
	}

	hideSidebarToggle() {
		this.layout.toggleHideSidebar();
	}
}
