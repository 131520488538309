// turn off debug
const FunctionOverrides = isProduction => {
  // override console logging in production
  if (isProduction) {
    console.warn(`👋🏾 Console output is disabled on production`);
    console.log = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.time = () => {};
    console.timeEnd = () => {};
    console.timeLog = () => {};
  } else {
    console.debug(`👋🏾 Console output is enabled on non production`);
  }
};
function validateFormControlWithPredicate(brokenRuleContainer) {
  return (control, predicate, rule) => {
    if (!predicate(control.value)) {
      brokenRuleContainer.push(rule);
      control.setErrors({
        invalid: true
      });
      return false;
    } else {
      control.setErrors(null);
      return true;
    }
  };
}
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function requiredPredicate(value) {
  return !!value;
}
function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function doesNotHaveWhiteSpacePredicate(value) {
  return !hasWhiteSpace(value);
}
function hasWhiteSpace(s) {
  if (!s) return;
  return /\s/g.test(s);
}
const creditCardValidator = formControl => {
  if (formControl.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
    return true;
  } else {
    return false;
  }
};
const emailValidator = formControl => {
  if (formControl.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { FunctionOverrides, creditCardValidator, doesNotHaveWhiteSpacePredicate, emailValidator, hasWhiteSpace, requiredPredicate, validateEmail, validateFormControlWithPredicate };
