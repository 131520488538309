import { inject } from '@angular/core';
import type { Route } from '@angular/router';
import { AuthService } from '@ft/lib/auth-lib';

export const LoginRoute: Route = {
	path: 'login',
	pathMatch: 'prefix',
	loadComponent: () => import('./login/login.component').then((mod) => mod.LoginComponent),
};

export const LogoutRoute: Route = {
	path: 'logout',
	pathMatch: 'prefix',
	loadComponent: () => import('./logout/logout.component').then((mod) => mod.LogoutComponent),
};

export const performLogin = (returnRoute = ''): void => {
	inject(AuthService).performLogin(returnRoute);
};

export const performLogout = (notify = true): void => {
	inject(AuthService).performLogout(notify);
};

export const UnsubscribeRoute: Route = {
	path: 'unsubscribe',
	pathMatch: 'prefix',
	loadComponent: () => import('./unsubscribe/unsubscribe.component').then((mod) => mod.UnsubscribeComponent),
};
