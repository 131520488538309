import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, interval, map } from 'rxjs';
import { BackgroundStore } from '../store/background.store';

@Injectable({
	providedIn: 'root',
})
export class BackgroundService {
	backgroundStore = inject(BackgroundStore);
	imageIndex = 0;
	intervalCount = 0;

	backgroundImage = computed(() => this.backgroundStore.selectBackgroundImage());
	allBackgroundImages = computed(() => this.backgroundStore.selectAllDocuments().map((x) => x.url));
	initialBackgroundImage = this.allBackgroundImages().length
		? this.allBackgroundImages()[0]
		: this.backgroundStore.backgroundImage();

	dynamicBackgroundImage$: Observable<string> = interval(this.intervalCount ? 45000 : 100).pipe(
		map(() => {
			this.intervalCount++;
			const backgrounds = this.backgroundStore.selectAllDocuments();
			if (!backgrounds.length) return this.backgroundStore.backgroundImage();
			const lastIndex = backgrounds.length - 1;
			const imageIndex = Math.min(lastIndex, Math.max(this.imageIndex + 1, 0));
			return backgrounds[imageIndex]?.url ?? this.backgroundStore.backgroundImage();
		}),
	);

	dynamicBackgroundImage = toSignal(this.dynamicBackgroundImage$, {
		initialValue: this.initialBackgroundImage,
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}
}
