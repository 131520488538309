import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
	selector: 'ft-output-type',
	templateUrl: './output-type.component.html',
	styleUrls: ['./output-type.component.scss'],
	imports: [NgTemplateOutlet, NgClass, DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputTypeComponent extends FieldArrayType {
	destroyRef = inject(DestroyRef);

	ngOnInit() {}
}
