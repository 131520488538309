import { ChangeDetectionStrategy, Component, Inject, OnInit, computed, inject } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCertificate, faUser, faUserPlus, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { AuthStore } from '@ft/lib/auth-lib';
import { DialogButtonComponent } from '@ft/lib/button-lib';
import { ContactService } from '@ft/lib/contact-lib';
import { HAS_NOTIFICATIONS } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { SubscriptionService } from '@ft/lib/stripe-lib';
import { UserNotificationService } from '@ft/lib/user-notifications-lib';

@Component({
	selector: 'ft-profile-info',
	templateUrl: './profile-info.component.html',
	styleUrls: ['./profile-info.component.scss'],
	imports: [DialogButtonComponent, FontAwesomeModule, MatTooltipModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoComponent implements OnInit {
	protected layout = inject(LayoutInfoService);
	protected authStore = inject(AuthStore);
	private environment = inject(EnvironmentService);
	protected contactService = inject(ContactService);
	protected notificationService = inject(UserNotificationService);
	protected subscriptionService = inject(SubscriptionService);

	userinfo = this.authStore.userinfo;
	photo: Blob | undefined;

	faUserSecret = faUserSecret;
	faUser = faUser;
	faUserPlus = faUserPlus;
	faCertificate = faCertificate;

	stripePK = this.environment.stripePK;

	shownName = computed(() => {
		return this.userinfo().fullname || this.userinfo().displayName || '';
	});

	verifiedMark = 'assets/icons/checkmark-green.svg';

	constructor(@Inject(HAS_NOTIFICATIONS) public hasNotifications: boolean) {
		// console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit() {}
}
