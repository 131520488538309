@if (userinfo(); as user) {
  <div class="user-info-component">
    @if (user.picture) {
      <div class="user-picture"><img [src]="user.picture" /></div>
    }
    @if (user.displayName || user.fullname) {
      <div class="user-attribute">
        <!-- <div class="user-attr">displayName:</div> -->
        <!-- <pre>{{ user | json }}</pre> -->
        @if (shownName()) {
          <div class="user-value" [matTooltip]="shownName()" matTooltipShowDelay="300" aria-label="user shown name">
            {{ user.fullname }}
          </div>
        }
        <sup>
          @if (user.isAdmin) {
            <fa-icon
              style="margin-left: 7px; vertical-align: super; font-size: small"
              [icon]="faUserPlus"
              class="accent"
            ></fa-icon>
          } @else if (user.isSubscribed) {
            <fa-icon
              style="margin-left: 7px; vertical-align: super; font-size: small"
              [icon]="faCertificate"
              class="accent"
            ></fa-icon>
          }
        </sup>
      </div>
    }
    @if (user.email) {
      <div class="user-attribute">
        <div class="user-attr">Email:</div>
        <div class="user-value">{{ user.email }}</div>
        @if (user.emailVerified) {
          <img [src]="verifiedMark" class="user-icon" alt="Verified" matTooltip="email address has been verified" />
        }
      </div>
    }
    @if (user.providerNames) {
      <div class="user-attribute">
        <div class="user-attr">Validation:</div>
        <div class="user-value">{{ user.providerNames }}</div>
      </div>
    }
    @if (user.groups && user.groups.length > 0) {
      <div class="user-attribute">
        <div class="user-attr">Groups:</div>
        <div class="user-value">{{ user.groups.join(', ') }}</div>
      </div>
    }

    <!-- buttons -->
    <div class="buttons-container">
      <!-- user notifications -->
      @if (authStore.isSubscribed()) {
        <ft-dialog-button
          class="button-component"
          [iconDefinition]="notificationService.buttonIcon"
          [buttonText]="notificationService.notificationText()"
          [tooltip]="notificationService.notificationTooltip()"
          [textPosition]="'after'"
          [disabled]="notificationService.notificationButtonDisabled()"
          [clickFunction]="notificationService.notificationDialog()"
        ></ft-dialog-button>
      }
      <!-- subscribe button && user.isSubscribed -->
      @if (!!stripePK()) {
        <ft-dialog-button
          class="button-component"
          [iconDefinition]="subscriptionService.buttonIcon"
          [buttonText]="subscriptionService.subscribeText()"
          [tooltip]="subscriptionService.subscribeTooltip()"
          [textPosition]="'after'"
          [disabled]="subscriptionService.subscribeButtonDisabled()"
          [clickFunction]="subscriptionService.subscribeDialog()"
        ></ft-dialog-button>
      }
      <!-- contact button -->
      @if (this.authStore.isAuthenticated()) {
        <ft-dialog-button
          class="button-component"
          [iconDefinition]="contactService.buttonIcon"
          [buttonText]="contactService.buttonText()"
          [tooltip]="contactService.contactTooptip()"
          [textPosition]="'after'"
          [disabled]="contactService.contactButtonDisabled()"
          [clickFunction]="contactService.contactDialog()"
        ></ft-dialog-button>
      }
    </div>
  </div>
}
