import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataService } from '@ft/lib/data-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { catchError, of, take, tap } from 'rxjs';

type UnsubscribeResult = {
	message: string;
};

@Component({
	standalone: true,
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeComponent implements OnInit {
	destroyRef = inject(DestroyRef);
	private dataService = inject(DataService);
	private env = inject(EnvironmentService);

	email = input<string>('');

	message = signal<string>('');

	constructor() {
		console.debug(`${this.constructor.name} - CONSTRUCTOR `);
	}

	ngOnInit(): void {
		const email = this.email().trim();
		console.debug(`${this.constructor.name} - ngOnInit - email=${email}`);
		if (!email) return;
		this.unsubscribeEmail(email);
	}

	unsubscribeEmail(email: string): void {
		const body = { email: email };
		const unsubscribeRequest = this.dataService
			.postPublic<UnsubscribeResult>({ path: `${this.env.baseUrl()}unsubscribe`, data: body })
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				take(1),
				tap((result: UnsubscribeResult) => {
					console.debug(`${this.constructor.name} - unsubscribeEmail - result=`, result);
					this.message.set(result.message);
				}),
				catchError((err: unknown) => {
					console.error(`${this.constructor.name} - unsubscribeEmail - err=`, err);
					this.message.set('An error occurred, please try again later');
					return of(err);
				}),
			);

		unsubscribeRequest.subscribe();
	}
} // end class
