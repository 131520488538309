import { ChangeDetectionStrategy, Component, Inject, OnInit, computed, inject, signal } from '@angular/core';

import { NgClass } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Artefact, ArtefactArticleViewComponent } from '@ft/lib/artefacts-lib';
import { BackgroundStore } from '@ft/lib/background-lib';
import { WELCOME_TEXT, WelcomeText } from '@ft/lib/core-lib';
import { DataService } from '@ft/lib/data-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { catchError, finalize, map, of, take } from 'rxjs';
import { LoginButtonComponent } from '../login-button/login-button.component';

@Component({
	selector: 'ft-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [ArtefactArticleViewComponent, FontAwesomeModule, LoginButtonComponent, NgClass],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent implements OnInit {
	protected layout = inject(LayoutInfoService);
	backgroundStore = inject(BackgroundStore);
	sanitizer = inject(DomSanitizer);
	dataService = inject(DataService);
	env = inject(EnvironmentService);

	headerText = signal<string>('');
	loginText = signal<string>('');
	welcomeText = signal<string>('');
	welcomeTextSmall = signal<string>('');
	welcomeArtefactsEndpoint = signal<string>('');

	layoutWelcomeText = computed<string>(() => {
		const welcomeText = this.layout.ltMediumScreen() ? this.welcomeTextSmall() : this.welcomeText();
		return typeof welcomeText === 'string' ? String(welcomeText).trim() : '';
	});

	welcomeArtefacts = signal<Artefact[]>([]);

	hasWelcomeArtefacts = computed(() => this.welcomeArtefacts().length > 0);

	welcomeBackgroundUrl = signal<string>('assets/logos/background.svg');

	sanitizedLoginText = computed<SafeHtml>(() => {
		return this.loginText() ? this.sanitizer.bypassSecurityTrustHtml(this.loginText()) : '';
	});

	sanitizedWelcomeText = computed<SafeHtml>(() => {
		return this.layoutWelcomeText() ? this.sanitizer.bypassSecurityTrustHtml(this.layoutWelcomeText()) : '';
	});

	constructor(@Inject(WELCOME_TEXT) private welcomeTextToken: WelcomeText) {
		console.debug(`${this.constructor.name} - constructor`);
		this.headerText.set(this.welcomeTextToken.headerText ?? '');
		this.loginText.set(this.welcomeTextToken.loginText ?? '');
		this.welcomeText.set(this.welcomeTextToken.welcomeText ?? '');
		this.welcomeTextSmall.set(this.welcomeTextToken.welcomeTextSmall ?? '');
		this.welcomeArtefactsEndpoint.set(this.welcomeTextToken.welcomeArtefactsEndpoint ?? '');
	}

	ngOnInit(): void {
		if (!!this.welcomeArtefactsEndpoint()) {
			console.debug(
				`${this.constructor.name} - ngOnInit - welcomeArtefactsEndpoint=${this.welcomeArtefactsEndpoint()}`,
			);
			this.loadWelcomeArtefacts();
		}
	}

	/**
	 * load welcome artefact
	 */
	loadWelcomeArtefacts(): void {
		const baseUrl = this.env.baseUrl();
		const endpoint = this.welcomeArtefactsEndpoint();
		if (!baseUrl || !endpoint) return;
		const url = `${baseUrl}${endpoint}`;
		console.debug(
			`${this.constructor.name} - loadWelcomeArtefacts - baseUrl=${baseUrl}, endpoint=${endpoint}, fullurl=${url}`,
		);

		const getArtefacts = this.dataService.getPublic<{ welcomeArtefacts: Artefact[] }>(url).pipe(
			take(1),
			map((data) => {
				return data?.welcomeArtefacts ? data.welcomeArtefacts : ([] as Artefact[]);
			}),
			catchError((err: unknown) => {
				console.error(`❌ getWelcomeArtefacts - error:`, err);
				return of([] as Artefact[]);
			}),
			finalize(() => {
				console.debug(`${this.constructor.name} - loadWelcomeArtefacts - FINALIZE`);
			}),
		);

		getArtefacts.subscribe((records) => {
			this.welcomeArtefacts.set(records);
		});
	}
}
