import { WelcomeText } from '@ft/lib/core-lib';

export const welcomeText: WelcomeText = {
	headerText: `Find Dance Competitions in Australia`,
	loginText: `Please Register or Login to Access Dance Competitions`,
	welcomeText:
		`<p>Welcome to the premier platform for information regarding dance competitions, festivals and events.</p>` +
		`<p></p><p></p>` +
		`<p>Join us in celebrating the art of dance and staying at the forefront of Australia's dance community.</p>`,
	welcomeTextSmall: `<p>Welcome to the premier platform for information regarding dance competitions, festivals and events.</p>`,
	welcomeArtefactsEndpoint: '',
};
