import { Injectable } from '@angular/core';
import Quill from 'quill';

const CustomFonts = ['', 'cursive', 'playfair', 'montserrat', 'helvetica', 'monospace'];

@Injectable({
	providedIn: 'root',
})
export class QuillEditService {
	public customFonts = CustomFonts;

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		const Font = Quill.import('formats/font') as any;
		if (!Font.whitelist || !this.customFonts.every((font) => Font.whitelist.includes(font))) {
			Font.whitelist = this.customFonts;
			Quill.register(Font, true);
		}
	}

	initQuillEditor() {
		// nothing to see here
	}
}
