import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuItem } from '../menu.service';

@Component({
	selector: 'ft-vertical-menu',
	templateUrl: './vertical-menu.component.html',
	styleUrls: ['./vertical-menu.component.scss'],
	imports: [NgClass, FontAwesomeModule, MatMenuModule, MatTooltipModule, RouterModule, MatListModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_VerticalMenuComponent implements OnInit {
	caller = input<string>('');
	menuItems = input<MenuItem[]>([]);
	itemClass = input<string>('');
	childDepth = input<number>(0);
	currentDepth = input<number>(0);

	navigatedMenuItem = output<MenuItem>();

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit - caller=${this.caller()}, menuItems=`, this.menuItems());
	}

	onClick(menuItem: MenuItem) {
		if (!!menuItem.menuPath) this.navigatedMenuItem.emit(menuItem);
	}
}
