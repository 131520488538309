import {} from '@angular/common';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type OnInit,
	computed,
	inject,
} from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { OrganisationService } from '@danceShared/organisations-shared/data/organisation.service';
import { Organisation } from '@danceShared/organisations-shared/organisation.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { AddButtonComponent } from '@furnas-technology/angular-library/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';

type FormlyModelKey = string | number;

@Component({
	selector: 'ft-organisation-options',
	templateUrl: './formly-organisation-options.component.html',
	styleUrls: ['./formly-organisation-options.component.scss'],
	imports: [
		NgSelectModule,
		FormsModule,
		MatFormFieldModule,
		MatButtonModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		AddButtonComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationOptionsComponent extends FieldType implements OnInit, AfterViewInit {
	destroyRef = inject(DestroyRef);
	organisationService = inject(OrganisationService);

	competitionService = inject(CompetitionService);

	faCirclePlus = faCirclePlus;

	typeahead$ = new Subject<string>();

	placeholder = '';
	keyAsString = '';
	required = false;
	touched = false;

	createOption = false;

	selectedId = computed<string | undefined>(() => {
		const key: FormlyModelKey = this.key as FormlyModelKey;
		this.keyAsString = String(key);
		const itemKey: string = key && this.model[key] ? (this.model[this.key as string] ?? '') : '';
		return itemKey || undefined;
	});

	organisations = this.organisationService.documents;
	sortedOrganisations = computed(() => {
		const organisations = this.organisations();
		return organisations.sort((a, b) =>
			a.organisationName.toLocaleLowerCase() > b.organisationName.toLocaleLowerCase() ? 1 : -1,
		);
	});

	// selectedOrganisation: Organisation | null = null;

	selectedOrganisation = computed<Organisation | null>(() => {
		if (!this.selectedId()) return null;
		const selectedOrganisation = this.organisations().find((item) => this.selectedId() === item._id);
		return selectedOrganisation ?? null;
	});

	constructor() {
		super();
	}

	ngOnInit() {
		this.placeholder = this.field.props?.placeholder ?? '';
		this.required = !!this.field.props?.required;
		this.createOption = !!this.field.props?.['createOption'];

		if (this.required) this.placeholder += ` *`;
	}

	ngAfterViewInit(): void {}

	onChange(organisation: Organisation | undefined) {
		const organisationId = organisation?._id ?? null;
		const key: FormlyModelKey = this.key as FormlyModelKey;
		this.model[key] = organisationId;

		// update the form
		const formControl = this.form.get(key as string);
		if (formControl) {
			formControl.setValue(organisationId);
			formControl.markAsDirty();
		}
	}

	onCreate() {
		this.organisationService.editDocument({ id: '' });
	}
}
