<div class="login-button-component" [class.shimmer-background]="authStore.authInProgress()">
  <button
    class="login-button"
    mat-flat-button
    aria-label="Login Button"
    (click)="login()"
    [disabled]="authStore.authInProgress() || authStore.isAuthenticated()"
    aria-label="Login Button"
  >
    <h2 class="login-text">
      @if (authStore.authInProgress()) {
      <ft-progress-spinner [spinMe]="true"></ft-progress-spinner>
      } @else { Login / Register }
    </h2>
  </button>
</div>
