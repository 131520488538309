import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { ImageStoreService } from '@ft/lib/media-lib';

@Injectable({
	providedIn: 'root',
})
export class EnvInitService {
	env = inject(EnvironmentService);
	imageService = inject(ImageStoreService);
	gss = inject(GlobalStore);

	constructor() {}

	init() {
		console.debug(`👉 EnvInitService - BEG`);
		this.gss.setInitStatus('Initialising environment');

		// set default language
		const language = navigator.language || 'en-AU';
		this.gss.setLanguage(language);

		const env = this.env.environment();

		// load images from environment
		this.imageService.loadAppImages(``, [
			{ imageName: `defaultLogo`, imageUrl: env.icon },
			{ imageName: `defaultLogoLandscape`, imageUrl: env.logoLandscape },
			{ imageName: `logoSquare`, imageUrl: env.logoSquare },
			{ imageName: `logoLandscape`, imageUrl: env.logoLandscape },
			{ imageName: `logoPortrait`, imageUrl: env.logoPortrait },
			{ imageName: `icon`, imageUrl: env.icon },
			{ imageName: `background`, imageUrl: env.background },
		]);

		console.debug(`👈 EnvInitService - BEG`);
		return env;
	}
}
