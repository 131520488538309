// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentConfig, EnvironmentConfigInput } from '@ft/lib/core-lib';
import { EnvironmentUrls } from '@ft/lib/environment-lib';
import { BuildDate } from '../../../../shared-scripts/build-date';

export const BuildEnvironment = (input: EnvironmentConfigInput): EnvironmentConfig => {
	const urls = EnvironmentUrls(input.baseApiUrl);

	const env: EnvironmentConfig = {
		production: input.production,
		stage: input.stage,
		appName: input.appName,
		buildDate: BuildDate,

		baseUrl: urls.baseApiUrl,
		authUrl: urls.authUrl,
		backgroundsUrl: '',

		stripePT: input.stripePT,
		stripePK: input.stripePK,
		stripePortal: input.stripePortal,

		graphdefaultUrl: `${input.baseApiUrl}graphql`,
		graphqlPublicUrl: `${input.baseApiUrl}graphqlQuery`,

		loginUrl: urls.loginUrl,
		homeUrl: urls.homeUrl,
		logoutUrl: urls.logoutUrl,

		loginCallback: `${window.location.origin}${urls.loginUrl}`,
		logoutCallback: `${window.location.origin}${urls.homeUrl}`,

		autoLogoutMinutes: 30,

		icon: `${urls.imageUrl}icon.svg`,
		background: `${urls.imageUrl}background.svg`,
		logoSquare: `${urls.imageUrl}square.png`,
		logoLandscape: `${urls.imageUrl}landscape.png`,
		logoPortrait: `${urls.imageUrl}portrait.png`,

		logoLandscapeTransparent: `${urls.imageUrl}landscape-transparent.png`,
		logoSquare512: `${urls.imageUrl}square-512.png`,
		logoText: `${urls.imageUrl}text.png`,
	};

	return env;
};
